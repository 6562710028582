import { combineReducers } from '@reduxjs/toolkit';

import accessGrantsStore from './accessGrants.store';
import channelsReducer from './channels.store';
import customersReducer from './customers.store';
import globalReducer from './global.store';
import instancesReducer from './instances.store';
import notificationReducer from './notification.store';
import partnersReducer from './partners.store';
import tagsViewReducer from './tags-view.store';
import uiReducer from './ui.store';
import userReducer from './user.store';
import usersReducer from './users.store';

const rootReducer = combineReducers({
    user: userReducer,
    users: usersReducer,
    ui: uiReducer,
    tagsView: tagsViewReducer,
    notification: notificationReducer,
    global: globalReducer,
    instances: instancesReducer,
    customers: customersReducer,
    channels: channelsReducer,
    accessGrants: accessGrantsStore,
    partners: partnersReducer,
});

export default rootReducer;

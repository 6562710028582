import type { Customer } from '../../../common/routes/customer';

import { request } from './request';

export const apiGetCustomerRoles = (customerId: string) => {
    return request<Customer.Roles.ListResponse>('get', `/customer/${customerId}/roles`);
};

export const apiGetCustomerChannels = (customerId: string) => {
    return request<Customer.Channels.Accounts.ListResponse>('get', `/customer/${customerId}/channels/accounts`);
};

export const apiGetCustomerChats = (
    customerId: string,
    channel: Customer.Channels.Accounts.Account,
    query?: string,
) => {
    return request<Customer.Chats.SearchResponse>('get', `/customer/${customerId}/chats/search`, {
        channelName: channel.channel,
        channelAccountId: channel.accountId,
        search: query,
    });
};

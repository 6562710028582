import type { Instances } from '../../../../common/routes/instances';
import type { FC } from 'react';

import './index.less';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MyInput from '@/components/basic/input';
import MyForm from '@/components/core/form';
import MyModal from '@/components/core/modal';
import { LocaleFormatter, useLocale } from '@/locales';
import { addInstanceAsync } from '@/stores/instances.action';

interface Props {
    visible: boolean;
    onCancel: () => void;
}

const initialValues: Instances.AddBody = {
    id: '',
    url: '',
};

const AddInstanceModal: FC<Props> = ({ visible, onCancel }) => {
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useLocale();
    const dispatch = useDispatch();

    const onClose = async (formData?: Instances.AddBody) => {
        if (formData) {
            try {
                setLoading(true);
                const success = Boolean(await dispatch(addInstanceAsync(formData)));

                if (success) {
                    onCancel();
                }
            } finally {
                setLoading(false);
            }
        } else {
            onCancel();
        }
    };

    return (
        <MyModal
            form={initialValues}
            formProps={{ layout: 'vertical', preserve: false, initialValues }}
            title={<LocaleFormatter id="instances.addInstance" />}
            open={visible}
            onClose={onClose}
            okText={<LocaleFormatter id="instances.addInstance" />}
            confirmLoading={loading}
            width={600}
            destroyOnClose
        >
            <MyForm.Item
                label={formatMessage({
                    id: 'instances.instanceId',
                })}
                name="id"
                rules={[
                    {
                        required: true,
                        message: formatMessage({
                            id: 'instances.instanceIdRequired',
                        }),
                    },
                ]}
            >
                <MyInput
                    placeholder={formatMessage({
                        id: 'instances.instanceIdPlaceholder',
                    })}
                />
            </MyForm.Item>
            <MyForm.Item
                label={formatMessage({
                    id: 'instances.instanceUrl',
                })}
                name="url"
                rules={[
                    {
                        required: true,
                        message: formatMessage({
                            id: 'instances.instanceUrlRequired',
                        }),
                    },
                ]}
            >
                <MyInput
                    placeholder={formatMessage({
                        id: 'instances.instanceUrlPlaceholder',
                    })}
                />
            </MyForm.Item>
        </MyModal>
    );
};

export default AddInstanceModal;

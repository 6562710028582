export const enUS_users = {
    'users.title': 'Users',
    'users.add': 'Add User',
    'users.add.success': 'User Added Successfully',
    'users.edit.success': 'User Edited Successfully',
    'users.add.contact': 'Add Contact',
    'users.edit': 'Edit User',
    'users.profile': 'My Profile',
    'users.table.header.name': 'Name',
    'users.table.header.email': 'Email',
    'users.table.header.permissions': 'Permissions',
};

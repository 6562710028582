import type { Segment } from './components/AccessFilters';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import type { FC } from 'react';

import './index.less';

import { message, Tag, Tooltip } from 'antd';
import Table from 'antd/es/table';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiGetAccessGrants } from '@/api/accessGrants.api';
import MyButton from '@/components/basic/button';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import { hasPermissions } from '@/stores/user.action';
import { loadUsersAsync } from '@/stores/users.action';

import { Customer } from '../../../../common/customer';
import { Permission } from '../../../../common/permissions';
import { AccessGrants } from '../../../../common/routes/access-grants';
import AccessFilters from './components/AccessFilters';
import CustomerActions from './components/actions';
import TooltipParagraph from './components/TooltipParagraph';

const AccessCustomersPage: FC = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { users } = useSelector((state) => state.users);
    const { formatMessage } = useLocale();

    /* Data */
    const [list, setList] = useState<AccessGrants.ListResponse['data']>([]);
    const [total, setTotal] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    /* Filter States */
    const [segment, setSegment] = useState<Segment>('my_access');
    const [showExpired, setShowExpired] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<AccessGrants.ListSortField>(AccessGrants.ListSortField.created);
    const [orderBy, setOrderBy] = useState<'asc' | 'desc'>('desc');
    const [status, setStatus] = useState<Customer.AccessGrantStatus[]>([
        Customer.AccessGrantStatus.pending,
        Customer.AccessGrantStatus.approved,
    ]);
    const [customer, setCustomer] = useState<string>('');
    const [grantNumber, setGrantNumber] = useState<number | undefined>(undefined);

    /* Permissions */
    const isAdmin = dispatch(hasPermissions([Permission.customersAccessGrantApprove]));
    const canManageUsers = dispatch(hasPermissions([Permission.manageUsers]));
    const isRequester = (uid: string) => user?._id === uid;

    const loadAccessGrants = (query?: AccessGrants.ListQuery) => {
        apiGetAccessGrants(query)
            .then(({ data }) => {
                if (!query?.skip) {
                    setList(data.data);
                    setPage(1);
                } else {
                    setList([...list, ...data.data]);
                }

                setTotal(data.total);
            })
            .catch((error) => {
                console.error(error);
                messageApi.error('Failed to load access grants');
            });
    };

    const generateQuery = (skip?: number) => {
        const query: AccessGrants.ListQuery = {
            showExpired,
            status,
            limit,
            skip,
        };

        if (sortBy) {
            query.sortField = sortBy;
            query.sortDirection = orderBy;
        }

        if (grantNumber) {
            query.number = grantNumber;
        }

        if (customer) {
            query.customerId = customer;
        }

        if (isAdmin) {
            if (segment === 'my_access') {
                query.uid = user?._id;
            } else if (segment === 'manage') {
                query.notUid = user?._id;
            }
        }

        return query;
    };

    const onClearAllFilters = () => {
        setShowExpired(false);
        setStatus([Customer.AccessGrantStatus.pending, Customer.AccessGrantStatus.approved]);
        setSortBy(AccessGrants.ListSortField.created);
        setOrderBy('desc');
        setCustomer('');
        setGrantNumber(undefined);
    };

    const onTableChange = (
        _: any,
        __: any,
        sorter: SorterResult<Customer.AccessGrant<true>> | SorterResult<Customer.AccessGrant<true>>[],
    ) => {
        if (sorter instanceof Array) return;

        const field = sorter.field as AccessGrants.ListSortField;

        if (Object.values(AccessGrants.ListSortField).includes(field)) {
            setSortBy(field);
            setOrderBy(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
    };

    const renderDate = (dateString: string) => {
        const date = dayjs(dateString),
            fullDate = date.format('lll');

        if (dayjs().diff(date, 'month') < 1) {
            return (
                <Tooltip title={fullDate} trigger={['hover', 'click']}>
                    {date.fromNow()}
                </Tooltip>
            );
        } else {
            return fullDate;
        }
    };

    useEffect(() => {
        loadAccessGrants(generateQuery());
        /* fetch on field change - instant */
    }, [segment, sortBy, orderBy, showExpired, status, customer]);

    useEffect(() => {
        debounce(() => {
            loadAccessGrants(generateQuery());
        }, 500)();
        /* fetch on field change - debounce */
    }, [grantNumber]);

    useEffect(() => {
        if (canManageUsers) {
            dispatch(loadUsersAsync());
        }
    }, []);

    const columns: ColumnsType<Customer.AccessGrant<true>> = [
        {
            title: formatMessage({ id: 'customers.table.header.number' }),
            dataIndex: 'number',
            sorter: (a, b) => a.number - b.number,
            defaultSortOrder: 'descend',
        },
        {
            title: formatMessage({ id: 'customers.table.header.customerId' }),
            dataIndex: 'customerId',
            sorter: (a, b) => a.customerId.localeCompare(b.customerId),
        },
        {
            title: formatMessage({ id: 'customers.table.header.instance' }),
            dataIndex: 'customerInstances',
            render: (instances?: {_id: string}[]) => {
                if (!instances) return '-';

                return instances.map(({ _id }) => _id).join(', ');
            },
        },
        ...(isAdmin && segment === 'manage' && canManageUsers
            ? [
                  {
                      title: formatMessage({ id: 'customers.table.header.user' }),
                      dataIndex: 'uid',
                      render: (uid: string) => {
                          const user = users[uid];

                          return user?.name || user?.email || uid;
                      },
                  },
              ]
            : []),
        {
            title: formatMessage({ id: 'customers.table.header.customerRoles' }),
            dataIndex: 'customerRoles',
            sorter: (a, b) => a.customerRoles.join().localeCompare(b.customerRoles.join()),
            render: (roles: string[]) => (
                <div>
                    {roles.map((role) => (
                        <Tag key={role} color="#546BD6">
                            {role}
                        </Tag>
                    ))}
                </div>
            ),
        },
        {
            title: formatMessage({ id: 'customers.table.header.requestMessage' }),
            dataIndex: 'requestMessage',
            render: (requestMessage: string | undefined) => {
                if (!requestMessage) return;

                return <TooltipParagraph ellipsis={{ rows: 2 }}>{requestMessage}</TooltipParagraph>;
            },
        },
        {
            title: formatMessage({ id: 'customers.table.header.status' }),
            dataIndex: 'status',
            render: (status: Customer.AccessGrantStatus) => {
                return <Tag>{status}</Tag>;
            },
        },
        {
            title: formatMessage({ id: 'customers.table.header.firstAccessAt' }),
            dataIndex: 'firstAccess',
            sorter: true,
            render: (firstAccess: string) => {
                if (!firstAccess) {
                    return <LocaleFormatter id="global.never" />;
                }

                return renderDate(firstAccess);
            },
        },
        {
            title: formatMessage({ id: 'customers.table.header.createdAt' }),
            dataIndex: 'created',
            sorter: true,
            render: (created: string) => {
                return renderDate(created);
            },
        },
        {
            title: formatMessage({ id: 'customers.table.header.expiresAt' }),
            dataIndex: 'expires',
            sorter: true,
            render: (expires: string) => {
                if (!expires) {
                    return <LocaleFormatter id="global.infininity" />;
                }

                return renderDate(expires);
            },
        },
        {
            title: formatMessage({ id: 'customers.table.header.actions' }),
            align: 'right',
            render: (_, record) => {
                return <CustomerActions record={record} isRequester={isRequester} isAdmin={isAdmin} />;
            },
        },
    ];

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="customers.access.title" />
                </h1>
                <div className="title-actions">
                    <MyButton type="primary" onClick={() => history.push('/customers/access/request')}>
                        {dispatch(hasPermissions([Permission.customersAccessGrantApprove])) ? (
                            <LocaleFormatter id="customers.access.grantAccess" />
                        ) : (
                            <LocaleFormatter id="customers.access.requestAccess" />
                        )}
                    </MyButton>
                </div>
            </div>
            <div className="content-with-filters">
                <AccessFilters
                    segment={segment}
                    onSegmentChange={setSegment}
                    showExpired={showExpired}
                    setShowExpired={setShowExpired}
                    status={status}
                    setStatus={setStatus}
                    customer={customer}
                    setCustomer={setCustomer}
                    onClearAllFilters={onClearAllFilters}
                    grantNumber={grantNumber}
                    setGrantNumber={setGrantNumber}
                />
                <Table
                    columns={columns}
                    dataSource={list}
                    onChange={onTableChange}
                    showSorterTooltip={{
						placement: 'bottomRight',
					}}
                    rowKey="_id"
                    sortDirections={['descend', 'ascend']}
                    pagination={{
                        total,
                        pageSize: limit,
                        current: page,
                        showSizeChanger: false,
                        showTotal: (total) => `Total: ${total} access grants`,
                        onChange: (page) => {
                            setPage(page);

                            if (page * limit > list.length && list.length < total) {
                                const skip = (page - 1) * limit;

                                loadAccessGrants(generateQuery(skip));
                            }
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default AccessCustomersPage;

import type { Facebook } from '../../../common/routes/channels';

import { request } from './request';

export const apiGetFacebookUserTokens = (query: Facebook.Tokens.ListQuery) => {
    return request<Facebook.Tokens.ListResponse>('get', '/channels/facebook/tokens', query);
};

export const apiAddFacebookUserToken = (params: Facebook.Tokens.AddBody) => {
    return request<Facebook.Tokens.GetResponse>('post', '/channels/facebook/tokens', params);
};

export const apiEditFacebookUserToken = (id: string, params: Facebook.Tokens.PatchBody) => {
    return request<Facebook.Tokens.GetResponse>('patch', `/channels/facebook/tokens/${id}`, params);
};

export const apiDeleteFacebookUserToken = (id: string) => {
    return request<Facebook.Tokens.GetResponse>('delete', `/channels/facebook/tokens/${id}`);
};

export const apiGetSingleFacebookUserToken = (id: string) => {
    return request<Facebook.Tokens.GetResponse>('get', `/channels/facebook/tokens/${id}`);
};

export const apiGetFacebookWebhooks = (query: Facebook.WebhooksRouter.ListQuery) => {
    return request<Facebook.WebhooksRouter.ListResponse>('get', '/channels/facebook/webhooks-router', query);
}

export const apiAddFacebookWebhook = (params: Facebook.WebhooksRouter.AddBody) => {
    return request<string>('post', '/channels/facebook/webhooks-router', params);
}

export const apiSubscribeFacebookWebhook = (id: string, params: Facebook.WebhooksRouter.SubscribeBody) => {
    return request<string>('post', `/channels/facebook/webhooks-router/${id}/subscriptions`, params);
}

export const apiUnsubscribeFacebookWebhook = (id: string, params: Facebook.WebhooksRouter.UnsubscribeBody) => {
    return request<string>('delete', `/channels/facebook/webhooks-router/${id}/subscriptions`, params);
}

export const apiDeleteFacebookWebhook = (id: string) => {
    return request<string>('delete', `/channels/facebook/webhooks-router/${id}`);
}



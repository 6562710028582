import type { AccessGrants } from '../../../common/routes/access-grants';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

const initialState: AccessGrantsState = {
    data: [],
    total: 0,
};

const accessGrantsSlice = createSlice({
    name: 'accessGrants',
    initialState,
    reducers: {
        setAccessGrants(state, action: PayloadAction<AccessGrants.ListResponse<true>>) {
            state.data = action.payload.data;
            state.total = action.payload.total;
        },
        addAccessGrant(state, action: PayloadAction<AccessGrants.PostResponse>) {
            state.data.push(action.payload);
            state.total++;
        },
        updateAccessGrant(state, action: PayloadAction<AccessGrants.PatchResponse>) {
            const index = state.data.findIndex((grant) => grant._id === action.payload._id);

            if (index !== -1) {
                state.data[index] = action.payload;
            }
        },
    },
});

export const { setAccessGrants, addAccessGrant, updateAccessGrant } = accessGrantsSlice.actions;

export default accessGrantsSlice.reducer;

export type AccessGrantsState = AccessGrants.ListResponse<true>;

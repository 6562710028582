import type { Pipelines } from '../../../common/routes/pipelines';

import { request } from './request';

export const apiGetPipelines = () => {
    return request<Pipelines.GetUnfinishedResponse>('get', '/pipelines/unfinished');
};

export const apiResumePipeline = (runId: string) => {
    return request<void>('post', `/pipelines/resume/${runId}`);
};

export const apiStopPipeline = (runId: string, op: 'cancel' | 'pause') => {
    return request<void>('post', `/pipelines/${op}/${runId}`);
};

export const apiTriggerExamplePipeline = ({ time = 1000 * 60 * 60, fail = false, resumable = true }) => {
    return request<void>('post', '/pipelines/test', {
        time,
        fail,
        resumable,
    });
};

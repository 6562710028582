import type { FC } from 'react';

import classNames from 'classnames';
import { GrClose, GrPause } from 'react-icons/gr';
import { TbProgress, TbProgressCheck, TbProgressX } from 'react-icons/tb';

import { ReactComponent as AccountSvg } from '@/assets/menu/account.svg';
import { ReactComponent as DashboardSvg } from '@/assets/menu/dashboard.svg';
import { ReactComponent as DocumentationSvg } from '@/assets/menu/documentation.svg';
import { ReactComponent as GuideSvg } from '@/assets/menu/guide.svg';
import { ReactComponent as PermissionSvg } from '@/assets/menu/permission.svg';

interface CustomIconProps {
    type: string;
    className?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
}

export const CustomIcon: FC<CustomIconProps> = ({ type, className, ...rest }) => {
    let com = <GuideSvg />;

    switch (type) {
        case 'guide':
            com = <GuideSvg />;
            break;
        case 'permission':
            com = <PermissionSvg />;
            break;
        case 'dashboard':
            com = <DashboardSvg />;
            break;
        case 'account':
            com = <AccountSvg />;
            break;
        case 'documentation':
            com = <DocumentationSvg />;
            break;
        case 'running':
            com = <TbProgress className="running" />;
            break;
        case 'completed':
            com = <TbProgressCheck className="completed" />;
            break;
        case 'failed':
            com = <TbProgressX className="failed" />;
            break;
        case 'paused':
            com = <GrPause className="paused" />;
            break;
        case 'close':
            com = <GrClose />;
    }

    return (
        <span className={classNames('anticon', className)} {...rest}>
            {com}
        </span>
    );
};

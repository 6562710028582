import { Checkbox, Divider, Segmented } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import MyButton from '@/components/basic/button';
import MyInput from '@/components/basic/input';
import PermissionGate from '@/components/basic/permission-gate';
import MySelect from '@/components/basic/select';
import { useLocale } from '@/locales';
import { searchCustomersAsync } from '@/stores/customers.action';

import { Customer } from '../../../../../common/customer';
import { Permission } from '../../../../../common/permissions';

export const SEGMENTS = Object.freeze({
	my_access: 'customers.access.segments.my_access',
	manage: 'customers.access.segments.manage',
});

export type Segment = keyof typeof SEGMENTS;

interface Props {
	segment: Segment;
	onSegmentChange: (value: Segment) => void;
	showExpired: boolean;
	setShowExpired: (value: boolean) => void;
	status: Customer.AccessGrantStatus[];
	setStatus: (value: Customer.AccessGrantStatus[]) => void;
	customer: string;
	setCustomer: (value: string) => void;
	onClearAllFilters: () => void;
	grantNumber: number | undefined;
	setGrantNumber: (value: number | undefined) => void;
}

const AccessFilters: React.FC<Props> = ({
	segment,
	onSegmentChange,
	showExpired,
	setShowExpired,
	status,
	setStatus,
	customer,
	setCustomer,
	onClearAllFilters,
	grantNumber,
	setGrantNumber,
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useLocale();
	const [customers, setCustomers] = useState<Customer.Info[]>([]);

	const fetchCustomers = async (query: string) => {
		const customers = await dispatch(searchCustomersAsync(query));

		if (customers) {
			setCustomers(customers);
		}
	};

	const onCustomerSearch = (query: string) => {
		if (query.length >= 2) {
			debounce(() => {
				fetchCustomers(query);
			}, 500)();
		}
	};

	useEffect(() => {
		if (segment !== 'manage' && !!grantNumber) {
			setGrantNumber(undefined);
		}
	}, [segment]);

	return (
		<div className="customers-filters">
			<PermissionGate permissions={[Permission.customersAccessGrantApprove]}>
				<>
					<Segmented
						value={segment}
						onChange={(value) => onSegmentChange(value as Segment)}
						options={Object.entries(SEGMENTS).map(([value, label]) => {
							return { value, label: formatMessage({ id: label }) };
						})}
						className="access-segmented-selector"
						size="large"
					/>
					<Divider type="vertical" />
				</>
			</PermissionGate>
			{segment === 'manage' && (
				<>
					<div className="customers-filters-box">
						<label className="text-2">
							{formatMessage({ id: 'customers.access.filters.grantNumber' })}
						</label>
						<MyInput
							value={grantNumber}
							onChange={(e) =>
								setGrantNumber(
									e.target.value ? parseInt(e.target.value) : undefined
								)
							}
							className="access-filters-select-wrapper grant-number-input"
							size="middle"
							allowClear
						/>
					</div>
					<Divider type="vertical" />
				</>
			)}
			{!grantNumber && (
				<>
					<div className="customers-filters-box">
						<label className="text-2">
							{formatMessage({ id: 'customers.access.filters.customer' })}
						</label>
						<MySelect
							value={customer}
							onChange={setCustomer}
							onSearch={onCustomerSearch}
							size="middle"
							options={customers.map((customer) => ({
								label: customer._id,
								value: customer._id,
							}))}
							placeholder="Customer ID"
							allowClear
							showSearch
							className="access-filters-select-wrapper"
						/>
					</div>
					<Divider type="vertical" />
					<div className="customers-filters-box">
						<label className="text-2">
							{formatMessage({ id: 'customers.access.filters.status' })}
						</label>
						<MySelect
							value={status}
							onChange={(value: Customer.AccessGrantStatus[]) =>
								setStatus(value)
							}
							placeholder="Status"
							mode="multiple"
							size="middle"
							allowClear
							options={Object.values(Customer.AccessGrantStatus).map(
								(status) => ({
									label: status,
									value: status,
								})
							)}
							className="access-filters-select-wrapper"
						/>
					</div>
					<Divider type="vertical" />
					<Checkbox
						checked={showExpired}
						onChange={(e) => setShowExpired(e.target.checked)}
					>
						{formatMessage({ id: 'customers.access.filters.showExpired' })}
					</Checkbox>
					<Divider type="vertical" />
				</>
			)}
			<MyButton onClick={onClearAllFilters} danger>
				{formatMessage({ id: 'customers.access.filters.clearAllFilters' })}
			</MyButton>
		</div>
	);
};

export default AccessFilters;

import type { Notifications } from '../../../common/routes/notifications';
import { request } from './request';

export const apiGetNotifications = (after?: Date | number, limit?: number) => {

    const params: Notifications.GetQuery<false> = { limit };
    if(after){
        params.createdAfter = after instanceof Date ? after.getTime() : after;
    }

    return request<Notifications.GetResponse>(
        'get', 
        '/notifications',
        params,
    );
}

import { Alert, List } from 'antd';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import MyButton from '@/components/basic/button';
import { LocaleFormatter } from '@/locales';
import { getAuthenticationUrlAsync } from '@/stores/accessGrants.action';

import { Customer } from '../../../../../common/customer';
import { useState } from 'react';
import { apiGetAccessGrants } from '@/api/accessGrants.api';
import useAsyncEffect from '@/hooks/useAsyncEffect';

interface Props {
    selectedCustomer: string;
    /**
     * Target Admin UI user ID
     */
    selectedUser: string;
    selectedRole: string;
    selectedChannel: string | null;
    selectedChat: string | null;
    selectedType: string[];
    /**
     * Current Admin UI user ID
     */
    currentUser: string;
}

const AccessPreview: React.FC<Props> = ({
    selectedCustomer,
    selectedUser,
    selectedRole,
    selectedChannel,
    selectedChat,
    selectedType,
    currentUser,
}) => {

    const [existingGrants, setExistingGrants] = useState<Customer.AccessGrant<true>[]>([]);

    const dispatch = useDispatch();

    const onLoginInto = async (id: string) => {
        const url = await dispatch(getAuthenticationUrlAsync(id));

        if (url) {
            window.open(url, '_blank', 'noopener noreferrer');
        }
    };

    const getMatchingGrants = () => {
        return existingGrants.filter((grant) => {

            if (selectedRole && !grant.customerRoles?.some((r) => r === selectedRole)) {
                return false;
            }

            if (selectedChannel && !grant.customerChatsAccess?.some((c) => c.accountId === selectedChannel)) {
                return false;
            }

            if (selectedChat && !grant.customerChatsAccess?.some((c) => c.id === selectedChat)) {
                return false;
            }

            if (
                selectedType.length &&
                grant.customerChatsAccess?.some((c) => c.type.every((type) => selectedType.includes(type)))
            ) {
                return false;
            }

            return true;
        });
    };

    useAsyncEffect(async () => {

        const result = await apiGetAccessGrants({
            uid: selectedUser,
            customerId: selectedCustomer,
            status: [Customer.AccessGrantStatus.approved, Customer.AccessGrantStatus.pending],
        });

        setExistingGrants(result?.data?.data ?? []);

    }, [selectedUser, selectedCustomer]);

    const matchingGrants = getMatchingGrants();

    if (!matchingGrants.length) return null;

    return (
        <div>
            <Alert
                message={<LocaleFormatter id="customer.access.request.matchTitle" />}
                type="info"
                showIcon
                description={
                    <div className="existing-access-wrapper">
                        <LocaleFormatter id="customer.access.request.useExisting" />
                        <List
                            dataSource={matchingGrants}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={`#${item.number}`}
                                        title={`Status: ${item.status}`}
                                        description={
                                            <>
                                            <div>{ `created at: ${dayjs(item.created).format('lll')}` }</div>
                                            { !!item.expires &&
                                            <div>{ `expires: ${dayjs(item.expires).format('lll')}` }</div>
                                            }
                                            </>
                                        }
                                    />

                                    {(item.status === Customer.AccessGrantStatus.approved && item.uid === currentUser) && (
                                        <MyButton
                                            type="primary"
                                            size="small"
                                            onClick={() => onLoginInto(item._id)}
                                            className="login-into-access"
                                        >
                                            <LocaleFormatter
                                                id="customers.access.loginInto"
                                                values={{ customerId: item.customerId }}
                                            />
                                        </MyButton>
                                    )}
                                </List.Item>
                            )}
                            bordered
                        />
                    </div>
                }
            />
        </div>
    );
};

export default AccessPreview;

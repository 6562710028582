import type { Dispatch } from '@reduxjs/toolkit';

import { apiGetNotifications } from '../api/notification.api';
import { apiStopPipeline, apiGetPipelines, apiResumePipeline, apiTriggerExamplePipeline } from '../api/pipeline.api';
import { setNotifications, setPipelines } from './notification.store';
import { createAsyncAction } from './utils';

export const loadNotificationsAsync = createAsyncAction<{ after?: Date | number; limit?: number }, void>((payload) => {
    return async (dispatch) => {
        const { data } = await apiGetNotifications(payload.after, payload.limit);

        if (data) {
            dispatch(setNotifications(data));
        }
    };
});

export const loadPipelinesAsync = () => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiGetPipelines();

        if (data) {
            dispatch(setPipelines(data));
        }
    };
};

export const resumePipelineAsync = createAsyncAction<string, void>((runId) => {
    return async () => {
        await apiResumePipeline(runId);
    };
});

export const stopPipelineAsync = createAsyncAction<{runId: string, op: 'cancel' | 'pause'}, void>(({runId, op}) => {
    return async () => {
        await apiStopPipeline(runId, op);
    };
});

export const triggerExamplePipelineAsync = createAsyncAction<
    {
        time?: number;
        fail?: boolean;
        resumable?: boolean;
    },
    void
>((payload) => {
    return async () => {
        await apiTriggerExamplePipeline(payload);
    };
});

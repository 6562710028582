import type { ButtonProps } from 'antd/es/button';
import type { FC } from 'react';

import './index.less';

import { Button } from 'antd';
import classNames from 'classnames';

interface MyButtonProps extends ButtonProps {
	className?: string;
}

const BaseButton: FC<MyButtonProps> = ({ className, ...props }) => {
	return (
		<Button {...props} className={classNames('MyButtonContainer', className)} />
	);
};

const MyButton = Object.assign(BaseButton, Button);

export default MyButton;

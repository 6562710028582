import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useLocale } from '@/locales';

const NotAuthorized: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useLocale();

    return (
        <Result
            status="403"
            title="403"
            subTitle={formatMessage({ id: 'global.tips.unauthorized' })}
            extra={
                <Button
                    type="primary"
                    onClick={() =>
                        navigate(`/login${'?from=' + encodeURIComponent(location.pathname)}`, { replace: true })
                    }
                >
                    {formatMessage({ id: 'global.tips.goToLogin' })}
                </Button>
            }
        />
    );
};

export default NotAuthorized;

import type { Permission } from '../../../common/permissions';
import type { FC, ReactElement } from 'react';
import type { RouteProps } from 'react-router';

import { useIntl } from 'react-intl';

import PrivateRoute from './PrivateRoute';

export type WrapperRouteProps = RouteProps & {
    /** document title locale id */
    titleId: string;
    /** authorization？ */
    auth?: boolean;
    /** permissions */
    permissions?: Permission[];
};

const WrapperRouteComponent: FC<WrapperRouteProps> = ({ titleId, auth, ...props }) => {
    const { formatMessage } = useIntl();

    if (titleId) {
        document.title = formatMessage({
            id: titleId,
        });
    }

    return auth ? <PrivateRoute {...props} /> : (props.element as ReactElement);
};

export default WrapperRouteComponent;

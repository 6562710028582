
/**
 * Facebook App information without secret values
 */
export interface App {
    _id: string;
}

/**
 * User access token information without token value
 */
export interface UserToken {
    /**
     * Name of the user.
     */
    name: string;
    /**
     * Facebook App ID access token generated for
     */
    app_id: string;
    /**
     * App-scoped user Facebook ID.
     */
    app_user_id: string;
    /**
     * Global user Facebook ID
     */
    user_id: string;
    /**
     * List of scopes granted to this token.
     */
    scopes: string[];
    /**
     * Timestamp in milliseconds when access token expires. Zero means never.
     */
    expires_at: number,
    /**
     * Timestamp in milliseconds when access token was issued.
     */
    issued_at: number,
    /**
     * Optionally any notes about this token.
     */
    notes?: string | null,
}

/**
 * @todo Keep all Facebook API types in @whatsper/facebook package
 */
export namespace Facebook {

    export namespace Webhooks {

        export enum SubscriptionObject {
            whatsapp_business_account = 'whatsapp_business_account',
            page = 'page',
            instagram = 'instagram',
            // application = 'application',
        }

        export enum WABASubscriptionField {
            'messages' = 'messages',
            'message_template_status_update' = 'message_template_status_update',
            'message_template_quality_update' = 'message_template_quality_update',
            'template_category_update' = 'template_category_update',
            'phone_number_name_update' = 'phone_number_name_update',
            'phone_number_quality_update' = 'phone_number_quality_update',
        }

        export enum PageSubscriptionField {
            'messages' = 'messages',
            'message_reactions' = 'message_reactions',
            'message_echoes' = 'message_echoes',
            'message_deliveries' = 'message_deliveries',
            'messaging_optins' = 'messaging_optins',
            'messaging_optouts' = 'messaging_optouts',
            'messaging_postbacks' = 'messaging_postbacks',
            'message_reads' = 'message_reads',
            'messaging_handovers' = 'messaging_handovers',
            'messaging_appointments' = 'messaging_appointments',
            'messaging_feedback' = 'messaging_feedback',
        }

        export enum InstagramSubscriptionField {
            'comments' = 'comments',
            'messaging_handover' = 'messaging_handover',
            'live_comments' = 'live_comments',
            'message_reactions' = 'message_reactions',
            'messages' = 'messages',
            'mentions' = 'mentions',
            'messaging_referral' = 'messaging_referral',
            'messaging_seen' = 'messaging_seen',
            'messaging_postbacks' = 'messaging_postbacks',
            'standby' = 'standby',
            'story_insights' = 'story_insights',
        }

        export const SubscriptionObjectFields = Object.freeze({
            [SubscriptionObject.whatsapp_business_account]: Object.values(WABASubscriptionField),
            [SubscriptionObject.page]: Object.values(PageSubscriptionField),
            [SubscriptionObject.instagram]: Object.values(InstagramSubscriptionField),
        } as const);

        export type ISubscriptionObjectFields = typeof SubscriptionObjectFields;
    }

}

import type { Customers } from '../../../common/routes/customers';

import { request } from './request';

export const apiGetCustomers = (query: Customers.ListQuery) => {
	return request<Customers.ListResponse>('get', '/customers', query);
};

export const apiGetSchema = (instanceId: string, partnerId?: string) => {
	return request<Customers.Setup.SchemaResponse>(
		'get',
		`/customers/${instanceId}/setup/schema${partnerId ? '/' + partnerId : ''}`
	);
};

export const apiSetupCustomer = (
	instanceId: string,
	data: Record<string, any>
) => {
	return request('post', `/customers/${instanceId}/setup`, data);
};

export const apiGetCustomerConfig = (
	instanceId: string,
	customerId: string
) => {
	return request<Customers.Config.GetResponse>(
		'get',
		`/customers/${instanceId}/config/${customerId}`
	);
};

/**
 *
 * @param instanceId
 * @param customerId
 * @param data A new config data
 * @returns
 */
export const apiUpdateCustomerConfig = (
	instanceId: string,
	customerId: string,
	data: Record<string, any>
) => {
	return request(
		'patch',
		`/customers/${instanceId}/config/${customerId}`,
		data
	);
};

export const apiGetCustomerMovingRequirements = (
	instanceId: string,
	customerId: string,
	toInstanceId: string
) => {
	return request('get', `/customers/${instanceId}/move/${customerId}`, {
		toInstanceId,
	});
};

export const apiMoveCustomer = (
	instanceId: string,
	customerId: string,
	toInstanceId: string
) => {
	return request('post', `/customers/${instanceId}/move/${customerId}`, {
		toInstanceId,
	});
};

import type { Partners } from '../../../../common/partners';
import type { ColumnsType } from 'antd/es/table';
import type { FC } from 'react';

import './index.less';

import { Table } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import { loadPartnersAsync } from '@/stores/partners.action';

import { Permission } from '../../../../common/permissions';

const PartnersPage: FC = () => {
    const dispatch = useDispatch();
    const { partners } = useSelector((state) => state.partners);
    const { formatMessage } = useLocale();

    useEffect(() => {
        dispatch(loadPartnersAsync());
    }, []);

    const onPartnerClick = (id: string) => {
        history.push(`/partners/${id}`);
    };

    const onAddPartner = () => {
        history.push('/partners/partner/add');
    };

    const columns: ColumnsType<Partners.Partner> = [
        {
            title: formatMessage({ id: 'partners.table.header.id' }),
            dataIndex: '_id',
        },
        {
            title: formatMessage({ id: 'partners.table.header.name' }),
            dataIndex: 'name',
        },
        {
            title: formatMessage({ id: 'partners.table.header.customers' }),
            dataIndex: 'customers',
            render: (customers: string[], record) => {
                const message = customers.length + ' customers';

                if (customers.length > 0) {
                    return (
                        <Link
                            to={`/customers?partnerId=${record._id}`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {message}
                        </Link>
                    );
                }

                return message;
            },
        },
    ];

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="partners.title" />
                </h1>
                <div className="title-actions">
                    <PermissionGate permissions={[Permission.partnersManage]}>
                        <MyButton type="primary" onClick={onAddPartner}>
                            <LocaleFormatter id="partners.profile.add" />
                        </MyButton>
                    </PermissionGate>
                </div>
            </div>
            <div className="partners-content">
                <Table
                    columns={columns}
                    dataSource={partners}
                    onRow={(partner: Partners.Partner) => ({
                        onClick: () => onPartnerClick(partner._id),
                    })}
                    rowClassName={'partner-row'}
                    rowKey="_id"
                />
            </div>
        </div>
    );
};

export default PartnersPage;

import { Permission } from '../permissions';

export interface IUser {
    _id: string;
    name: string;
    permissions: Permission[];
    /** URL of user image */
    avatar?: string;
    email?: string;
    /**
     * Allow user to login and access resources from these IPs only.
     * If not specified, or array is empty - then not restricted.
     */
    IPsAllowed?: string[];
    contacts?: UserContact[];
    /**
     * ID of partner this user belongs to
     */
    partnerId?: string;
    disabled?: boolean;
    /**
     * Keypair to generate and verify JSON Web Tokens to access external APIs
     * on behalf of this user
     */
    apiKey?: {
        /**
         * Public key
        */
       publicKey: string;
       /**
        * Number of tokens generated with this keypair
        */
       tokensCount: number;
    };
}

export enum UserContactType {
    /**
     * WhatsApp number
     */
    whatsapp = 'whatsapp',
    /**
     * Phone number for SMS
     */
    // sms = 'sms',
}

export interface UserContact {
    type: UserContactType;
    /**
     * Could be used for authentication methods or not
     */
    secure: boolean;
    /**
     * Phone or WhatsApp number, or other contact value
     */
    value: string;
}
export interface UserSession<IsJSON extends boolean = false> {
    /**
     * IP address from which this session was created
     */
    ip: string;
    /**
     * Datetime when this session expires
     */
    expire: IsJSON extends false ? Date : string;
    /**
     * Client IPs allowed
     */
    IPsAllowed?: string[];
    /**
     * Session was started by recovery code
     */
    recovery?: true;
}

import { WebsiteChat } from "./website_chat";
import { Notifications } from "./notifications";
import { Pipelines } from "./pipelines";

export namespace Events {

    export const PING_MSG = 'ping';
    
    export enum Name {
        notificationCreated = 'notificationCreated',
        pipelineCreated = 'pipelineCreated',
        pipelineUpdated = 'pipelineUpdated',
        websiteChatStateUpdated = 'websiteChatStateUpdated',
    }
    
    export type NotificationCreatedData<IsJSON extends boolean = true, IDType extends any = string> = Notifications.Notification<IsJSON> & {_id: IDType};
    
    export type PipelineCreatedData<IsJSON extends boolean = true, IDType extends any = string> = Pipelines.Run<IsJSON> & {_id: IDType};
    
    export type PipelineUpdatedData<IsJSON extends boolean = true, IDType extends any = string> = PipelineCreatedData<IsJSON, IDType>;
    
    export type EventNameDataMap<IsJSON extends boolean = true> = {
        [Name.notificationCreated]: NotificationCreatedData<IsJSON>;
        [Name.pipelineCreated]: PipelineCreatedData<IsJSON>;
        [Name.pipelineUpdated]: PipelineUpdatedData<IsJSON>;
        [Name.websiteChatStateUpdated]: WebsiteChat.State;
    }
}

import type { ReactNode } from 'react';

import { Popconfirm } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MyButton from '@/components/basic/button';
import { ExpireDatePicker } from '@/components/accessGrants/ExpireDatePicker';
import MyInput from '@/components/basic/input';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import {
    approveAccessGrantAsync,
    discardAccessGrantAsync,
    getAuthenticationUrlAsync,
    rejectAccessGrantAsync,
    revokeAccessGrantAsync,
} from '@/stores/accessGrants.action';

import { Customer } from '../../../../../common/customer';

interface Props {
    record: Customer.AccessGrant<true>;
    isRequester: (uid: string) => boolean;
    isAdmin: boolean;
}

const CustomerActions: React.FC<Props> = ({ record, isRequester, isAdmin }) => {
    const { formatMessage } = useLocale();
    const dispatch = useDispatch();

    const [expiresAt, setExpiresAt] = useState<number>(Date.now());
    const [reason, setReason] = useState<string>('');

    const onLoginInto = async () => {
        const url = await dispatch(getAuthenticationUrlAsync(record._id));

        if (url) {
            window.open(url, '_blank', 'noopener noreferrer');
        }
    };

    const conditionalRender = (condition: boolean, element: ReactNode) => {
        if (condition) {
            return element;
        }
    };

    return (
        <div className="table-actions-container">
            {conditionalRender(
                record.status === Customer.AccessGrantStatus.pending && (isRequester(record.uid) || isAdmin),
                <MyButton
                    type="primary"
                    size="small"
                    onClick={() =>
                        history.push('/customers/access/request', {
                            grant: record,
                            isEdit: true,
                        })
                    }
                >
                    <LocaleFormatter id="customers.access.edit" />
                </MyButton>,
            )}
            {conditionalRender(
                record.status === Customer.AccessGrantStatus.approved && isRequester(record.uid),
                <MyButton type="primary" size="small" onClick={onLoginInto}>
                    <LocaleFormatter id="customers.access.loginInto" values={{ customerId: record.customerId }} />
                </MyButton>,
            )}
            {conditionalRender(
                record.status === Customer.AccessGrantStatus.pending && isAdmin,
                <Popconfirm
                    title={formatMessage({ id: 'customers.access.approveModalTitle' })}
                    description={
                        <div className="actions-modal-container">
                            <div>{formatMessage({ id: 'customers.access.approveModalExpires' })}</div>
                            <ExpireDatePicker
                                value={dayjs(expiresAt)}
                                onChange={(date) => {
                                    if (date) {
                                        setExpiresAt(date.valueOf());
                                    }
                                }}
                                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                            />
                        </div>
                    }
                    onConfirm={() => {
                        dispatch(approveAccessGrantAsync(record._id, expiresAt));
                    }}
                    okButtonProps={{ disabled: !expiresAt }}
                    onCancel={() => setExpiresAt(Date.now())}
                    okText={formatMessage({ id: 'global.approve' })}
                    zIndex={1200}
                >
                    <MyButton type="primary" size="small">
                        <LocaleFormatter id="global.approve" />
                    </MyButton>
                </Popconfirm>,
            )}
            {conditionalRender(
                record.status === Customer.AccessGrantStatus.approved && isAdmin,
                <Popconfirm
                    title={formatMessage({ id: 'customers.access.revokeModalTitle' })}
                    description={formatMessage({ id: 'customers.access.revokeModalDescription' })}
                    onConfirm={() => dispatch(revokeAccessGrantAsync(record._id))}
                    okText={formatMessage({ id: 'global.revoke' })}
                    okButtonProps={{ danger: true }}
                    zIndex={1200}
                >
                    <MyButton type="primary" size="small" danger>
                        <LocaleFormatter id="global.revoke" />
                    </MyButton>
                </Popconfirm>,
            )}
            {conditionalRender(
                record.status === Customer.AccessGrantStatus.pending && isRequester(record.uid),
                <Popconfirm
                    title={formatMessage({ id: 'customers.access.discardModalTitle' })}
                    description={formatMessage({ id: 'customers.access.discardModalDescription' })}
                    onConfirm={() => dispatch(discardAccessGrantAsync(record._id))}
                    okText={formatMessage({ id: 'global.discard' })}
                    zIndex={1200}
                >
                    <MyButton type="primary" size="small" danger>
                        <LocaleFormatter id="global.discard" />
                    </MyButton>
                </Popconfirm>,
            )}
            {conditionalRender(
                record.status === Customer.AccessGrantStatus.pending && isAdmin,
                <Popconfirm
                    title={formatMessage({ id: 'customers.access.rejectModalTitle' })}
                    description={
                        <div className="actions-modal-container">
                            <div>{formatMessage({ id: 'customers.access.rejectModalReason' })}</div>
                            <MyInput.TextArea value={reason} onChange={(e) => setReason(e.target.value)} />
                        </div>
                    }
                    onConfirm={() => dispatch(rejectAccessGrantAsync(record._id, reason))}
                    okButtonProps={{ danger: true }}
                    okText={formatMessage({ id: 'global.reject' })}
                    onCancel={() => setReason('')}
                    zIndex={1200}
                >
                    <MyButton type="primary" size="small" danger>
                        <LocaleFormatter id="global.reject" />
                    </MyButton>
                </Popconfirm>,
            )}
        </div>
    );
};

export default CustomerActions;

export const enUS_partners = {
    'partners.title': 'Partners',
    'partners.profile.view': 'Partner Profile',
    'partners.profile.add': 'Add Partner',
    'partners.profile.edit': 'Edit Partner',
    'partners.profile.name': 'Name',
    'partners.profile.id': 'Partner ID',
    'partners.profile.customers': 'Customers (total: {total})',
    'partners.profile.customers.extra': 'Separate multiple customers with commas',
    'partners.table.header.id': 'ID',
    'partners.table.header.name': 'Name',
    'partners.table.header.customers': 'Customers',
    'partners.add.success': 'Partner has been added successfully',
    'partners.edit.success': 'Partner has been updated successfully',
};

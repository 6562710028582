import { ConfigProvider, Spin, theme as a } from 'antd';
import enUS from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import dayjsDurationPlugin from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.extend(dayjsDurationPlugin);
dayjs.extend(localizedFormat);

import { Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { history, HistoryRouter } from '@/routes/history';

import { attachListeners } from './events';
import useAsyncEffect from './hooks/useAsyncEffect';
import i18n from './i18n';
import { localeConfig } from './locales';
import RenderRouter from './routes';
import { setGlobalState } from './stores/global.store';
import { loadSinglePartnerAsync } from './stores/partners.action';
import { loadCurrentUserAsync } from './stores/user.action';

const App: React.FC = () => {
    const { locale } = useSelector((state) => state.user);
    const { theme, loading } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const setTheme = (dark = false) => {
        dispatch(
            setGlobalState({
                theme: dark ? 'dark' : 'light',
            }),
        );
    };

    useAsyncEffect(async () => {
        attachListeners();
        const user = await dispatch(loadCurrentUserAsync());

        /* Fetch user's partner profile */
        if (user?.partnerId) {
            dispatch(loadSinglePartnerAsync(user.partnerId));
        }
    }, []);

    /** initial theme */
    useEffect(() => {
        setTheme(theme === 'dark');

        // watch system theme change
        if (!localStorage.getItem('theme')) {
            const mql = window.matchMedia('(prefers-color-scheme: dark)');

            function matchMode(e: MediaQueryListEvent) {
                setTheme(e.matches);
            }

            mql.addEventListener('change', matchMode);
        }
    }, []);

    // set the locale for the user
    // more languages options can be added here
    useEffect(() => {
        if (locale === 'en_US') {
            dayjs.locale('en');
        }
    }, [locale]);

    /**
     * handler function that passes locale
     * information to ConfigProvider for
     * setting language across text components
     */
    const getAntdLocale = () => {
        if (locale === 'en_US') {
            return enUS;
        }
    };

    return (
        <ConfigProvider
            locale={getAntdLocale()}
            componentSize="middle"
            theme={{
                token: { colorPrimary: '#546BD6' },
                algorithm: theme === 'dark' ? a.darkAlgorithm : a.defaultAlgorithm,
            }}
        >
            <IntlProvider locale={locale.split('_')[0]} messages={localeConfig[locale]}>
                <I18nextProvider i18n={i18n}>
                    <HistoryRouter history={history}>
                        <Suspense fallback={null}>
                            <Spin spinning={loading} className="app-loading-wrapper" />
                            <RenderRouter />
                        </Suspense>
                    </HistoryRouter>
                </I18nextProvider>
            </IntlProvider>
        </ConfigProvider>
    );
};

export default App;

import type { MenuList } from '../../../interface/layout/menu.interface';

import { Menu } from 'antd';
import { type FC } from 'react';
import * as antdicons from 'react-icons/ai';
import { PiHandshakeLight } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setUI } from '@/stores/ui.store';
import { hasPermissions } from '@/stores/user.action';

import { Permission } from '../../../../../common/permissions';

const icons = {...antdicons, PiHandshakeLight};

const menuList: MenuList = [
    {
        code: 'dashboard',
        label: {
            en_US: 'Dashboard',
        },
        icon: 'AiOutlineDashboard',
        path: '/dashboard',
    },
    {
        code: 'customers',
        label: {
            en_US: 'Customers',
        },
        icon: 'AiOutlineShop',
        path: '/customers',
        permissions: [Permission.listCustomers],
        children: [
            {
                code: 'customersList',
                label: {
                    en_US: 'Customers list',
                },
                path: '/customers',
            },
            {
                code: 'customersAccess',
                label: {
                    en_US: 'Access',
                },
                path: '/customers/access',
            },
        ],
    },
    {
        code: 'instances',
        label: {
            en_US: 'Instances',
        },
        icon: 'AiOutlineGroup',
        path: '/instances',
        permissions: [Permission.manageInstances],
    },
    {
        code: 'users',
        label: {
            en_US: 'Users',
        },
        icon: 'AiOutlineTeam',
        path: '/users',
        permissions: [Permission.manageUsers],
        children: [
            {
                code: 'usersList',
                label: {
                    en_US: 'Users list',
                },
                icon: 'AiOutlineMenu',
                path: '/users',
            },
            {
                code: 'usersAdd',
                icon: 'AiOutlineUser',
                label: {
                    en_US: 'Add new user',
                },
                path: '/users/add',
            },
        ],
    },
    {
        code: 'partners',
        label: {
            en_US: 'Partners',
        },
        icon: 'PiHandshakeLight',
        path: '/partners',
        permissions: [Permission.partnersList],
        condition: (user) => !user?.partnerId,
    },
    {
        code: 'channels',
        label: {
            en_US: 'Channels',
        },
        icon: 'AiOutlineApartment',
        path: '/channels',
        permissions: [Permission.listFacebookUserTokens],
        children: [
            {
                code: 'facebook',
                label: {
                    en_US: 'Facebook',
                },
                path: '/channels/facebook',
                type: 'group',
                children: [
                    {
                        code: 'userTokens',
                        label: {
                            en_US: 'User Tokens',
                        },
                        path: '/channels/facebook/tokens',
                    },
                    {
                        code: 'webhooksRouter',
                        label: {
                            en_US: 'Webhooks router',
                        },
                        path: '/channels/facebook/webhooks',
                        permissions: [Permission.facebookWebhooksRouterList],
                    },
                ],
            },
        ],
    },
];

interface MenuProps {
    openKey?: string;
    onChangeOpenKey: (key?: string) => void;
    selectedKey: string;
    onChangeSelectedKey: (key: string) => void;
}

const MenuComponent: FC<MenuProps> = (props) => {
    const { openKey, onChangeOpenKey, selectedKey, onChangeSelectedKey } = props;
    const { device, locale, menuCollapsed } = useSelector((state) => state.ui);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const getTitle = (menu: MenuList[number]) => {
        return (
            <Link className="title-container" to={menu.path}>
                <span>{menu.label[locale]}</span>
            </Link>
        );
    };

    const getIcon = (menu: MenuList[number], shouldHide?: boolean) => {
        if (shouldHide) {
            return null;
        }

        const Ic = menu.icon ? icons[menu.icon as keyof typeof icons] : null;

        return Ic && <Ic />;
    };

    const onMenuClick = (path: string) => {
        onChangeSelectedKey(path);

        if (device !== 'DESKTOP') {
            dispatch(
                setUI({
                    menuCollapsed: true,
                }),
            );
        }
    };

    const onOpenChange = (keys: string[]) => {
        const key = keys.pop();

        onChangeOpenKey(key);
    };

    return (
        <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            openKeys={openKey ? [openKey] : []}
            onOpenChange={onOpenChange}
            onSelect={(k) => onMenuClick(k.key)}
            className="layout-page-sider-menu text-2"
            items={menuList
                .filter(({ permissions = [] }) => dispatch(hasPermissions(permissions)))
                .filter(({ condition }) => !condition || (user && condition(user)))
                .map((menu) => {
                    return menu.children
                        ? {
                              key: menu.code,
                              label: getTitle(menu),
                              icon: getIcon(menu),
                              children: menu.children.map((child) => ({
                                  key: child.path,
                                  label: getTitle(child),
                                  icon: getIcon(child, menuCollapsed),
                                  type: child.type,
                                  children: child.children?.map((subChild) => ({
                                      key: subChild.path,
                                      label: getTitle(subChild),
                                      icon: getIcon(subChild, menuCollapsed),
                                  })),
                              })),
                          }
                        : {
                              key: menu.path,
                              label: getTitle(menu),
                              icon: getIcon(menu),
                          };
                })}
        ></Menu>
    );
};

export default MenuComponent;

import type { Customer } from '../../../common/routes/customer';

import { apiGetCustomerChannels, apiGetCustomerChats, apiGetCustomerRoles } from '@/api/customer.api';

export const loadCustomerRolesAsync = (customerId: string) => {
    return async () => {
        const { data } = await apiGetCustomerRoles(customerId);

        if (data) {
            return data;
        }

        return null;
    };
};

export const loadCustomerChannelsAsync = (customerId: string) => {
    return async () => {
        const { data } = await apiGetCustomerChannels(customerId);

        if (data) {
            return data;
        }

        return null;
    };
};

export const loadCustomerChatsAsync = (
    customerId: string,
    channel: Customer.Channels.Accounts.Account,
    query?: string,
) => {
    return async () => {
        const { data } = await apiGetCustomerChats(customerId, channel, query);

        if (data) {
            return data;
        }

        return null;
    };
};

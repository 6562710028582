import type { Partners } from '../../../common/routes/partners';
import type { Dispatch } from '@reduxjs/toolkit';

import {
    apiGetCustomersSetupValues,
    apiGetPartners,
    apiGetSinglePartner,
    apiPatchPartner,
    apiPostPartner,
} from '../api/partners.api';
import { addPartner, editPartner, setMyPartner, setPartners } from './partners.store';
import { createAsyncAction } from './utils';

export const loadPartnersAsync = () => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiGetPartners();

        if (data) {
            dispatch(setPartners(data));
        }
    };
};

export const searchPartnersAsync = (search: string) => {
    return async () => {
        const { data } = await apiGetPartners(search);

        if (data) {
            return data.data;
        }

        return false;
    };
};

export const addPartnerAsync = createAsyncAction<Partners.PostBody>((payload) => {
    return async (dispatch) => {
        const { data } = await apiPostPartner(payload);

        if (data) {
            dispatch(addPartner(data));
            dispatch(setMyPartner(data));

            return true;
        }

        return false;
    };
});

export const editPartnerAsync = createAsyncAction<{
    partnerId: string;
    body: Partners.PatchBody;
}>(({ body, partnerId }) => {
    return async (dispatch) => {
        const { data } = await apiPatchPartner(body, partnerId);

        if (data) {
            dispatch(editPartner(data));
            dispatch(setMyPartner(data));

            return true;
        }

        return false;
    };
});

export const loadSinglePartnerAsync = createAsyncAction<string>((payload) => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiGetSinglePartner(payload);

        if (data) {
            dispatch(setMyPartner(data));

            return true;
        }

        return false;
    };
});

export const loadCustomersSetupValuesAsync = createAsyncAction<void>(() => {
    return async () => {
        const { data } = await apiGetCustomersSetupValues();

        if (data) {
            return data;
        }

        return false;
    };
});

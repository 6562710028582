import type { Auth } from '../../../common/routes/auth';

import { request } from './request';

export type LoginParams = Auth.PostLoginPasswordBody;

export const apiGetSession = () => request<Auth.MeResponse<true>>('get', '/auth/me');

export const apiLogin = (data: LoginParams) => request<Auth.PostLoginPasswordResponse<true>>('post', '/auth/login/password', data);

export const apiLogout = () => request<void>('post', '/auth/logout');

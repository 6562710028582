import { useEffect } from 'react';

const useUnsavedChangesBrowserWarning = (isDirty?: boolean) => {
    useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [isDirty]);
};

export default useUnsavedChangesBrowserWarning;

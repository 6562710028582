import type { Pipelines } from '../../../../../common/pipelines';
import type { StepProps } from 'antd';
import type { FC } from 'react';

import { Alert, Button, Steps } from 'antd';

import MyModal from '@/components/core/modal';
import { useLocale } from '@/locales';
import { GrResume } from 'react-icons/gr';

import './PipelinePreviewModal.less';

interface Props {
    visible: boolean;
    onClose: () => void;
    onResumePipeline: () => void;
    pipeline: Pipelines.Run<true, string>;
}

const PipelinePreviewModal: FC<Props> = ({ visible, onClose, pipeline, onResumePipeline }) => {
    const { formatMessage } = useLocale();

    const getStatus = (index: number) => {
        if (index < (pipeline?.completedTaskIndex || 0)) {
            return 'finish';
        } else if (pipeline?.error?.index === index) {
            return 'error';
        } else if (index === (pipeline?.completedTaskIndex || 0)) {
            return 'process';
        } else {
            return 'wait';
        }
    };

    const renderActions = () => {
        if(pipeline.resumable && (pipeline.paused || !!pipeline.error)){
            return (
                <div className="actions">
                    <Button 
                        type="primary" 
                        icon={<GrResume />}
                        onClick={ onResumePipeline }
                    >
                        { formatMessage({ id: 'app.notice.resumePipeline' }) }
                    </Button>
                </div>
            );
        }
        return null;
    }

    const items: StepProps[] = pipeline?.tasks.map((task, index) => ({
        title: task.description || task.name,
        status: getStatus(index),
    }));

    return (
        <div
            onClick={(e) => {
                // stops underlying notice popover from closing
                e.stopPropagation();
                e.preventDefault();
            }}
            className="pipeline-preview"
        >
            <MyModal
                title={pipeline?.description || pipeline?.name}
                open={visible}
                onClose={onClose}
                width={600}
                zIndex={1200}
                destroyOnClose
                footer={false}
            >
                <div className="pipeline-preview-container">
                    {pipeline?.error?.message && (
                        <Alert
                            message={formatMessage({ id: 'global.error' })}
                            description={
                                <>
                                { pipeline?.error?.message }
                                { renderActions() }
                                </>
                            }
                            type="error"
                            className="alert"
                            showIcon
                        />
                    )}
                    {pipeline?.paused && (
                        <Alert
                            message={formatMessage({ id: 'app.notice.paused' })}
                            description={
                                <>
                                { formatMessage({ id: 'app.notice.pausedDescription' }) }
                                { renderActions() }
                                </>
                            }
                            type="info"
                            className="alert"
                            showIcon
                        />
                    )}
                    <Steps current={pipeline?.completedTaskIndex} direction="vertical" items={items} />
                </div>
            </MyModal>
        </div>
    );
};

export default PipelinePreviewModal;

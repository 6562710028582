import type { Pipelines } from '../../../../../common/pipelines';
import type { FC } from 'react';

import { List, Popconfirm, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { GrResume, GrPause } from 'react-icons/gr';
import { MdOutlineDeleteForever } from "react-icons/md";
import { useDispatch } from 'react-redux';

import MyButton from '@/components/basic/button';
import { useLocale } from '@/locales';
import { stopPipelineAsync, resumePipelineAsync } from '@/stores/notification.action';

import { CustomIcon } from '../customIcon';

type PipelineState = 'running' | 'completed' | 'failed' | 'paused';

interface Props {
    pipelines: Pipelines.Run<true, string>[];
    setPreviewTaskId: (id: string) => void;
}

const HeaderPipelinesComponent: FC<Props> = ({ pipelines, setPreviewTaskId }) => {
    const { formatMessage } = useLocale();
    const dispatch = useDispatch();

    const getPipelineStateTooltip = useCallback((item: Pipelines.Run<true, string>) => {
        const { createdAt, completedAt, failedAt, resumedAt, startedAt } = item;
        const { state } = getPipelineStates(item);

        const label: {[key in PipelineState]?: string} = {
            running: 'Running for:',
            completed: 'Completed in:',
            failed: 'Failed since:',
        };

        const duration: {[key in PipelineState]?: number} = {
            running: dayjs().diff(dayjs(createdAt)),
            completed: dayjs(startedAt).diff(dayjs(completedAt)),
            failed: dayjs(resumedAt ? resumedAt : startedAt).diff(dayjs(failedAt)),
        };

        return (
            <div className="state-tooltip">
                <span>{formatMessage({ id: 'app.notice.state' }, { state })}</span>
                { (!!label[state] && typeof duration[state] === 'number') &&
                <span>
                    {label[state]} {dayjs.duration(duration[state]!).humanize()}
                </span>
                }
            </div>
        );
    }, []);

    const getPipelineStates = (item: Pipelines.Run<true, string>) => {
        const { cancelled, completedAt, resumable, error, paused } = item;

        const canCancel = !cancelled && !completedAt;
        const canResume = resumable && !cancelled && !completedAt && (paused || error);
        const completed = !!completedAt;
        const running = !completed && !cancelled && !error && !paused;
        const canPause = running && resumable;
        let state: PipelineState = 'failed';
        if(completed){
            state = 'completed';
        } else if(running){
            state = 'running';
        } else if(paused){
            state = 'paused';
        }

        return {
            canCancel,
            canPause,
            canResume,
            state,
        };
    };

    const icons = {
        running: <CustomIcon type="running" className="pipeline-state-icon" />,
        completed: <CustomIcon type="completed" className="pipeline-state-icon" />,
        failed: <CustomIcon type="failed" className="pipeline-state-icon" />,
        paused: <CustomIcon type="paused" className="pipeline-state-icon" />,
    };

    return (
        <List
            dataSource={pipelines}
            renderItem={(item) => {
                const { state, canCancel, canResume, canPause } = getPipelineStates(item);

                const actions: React.ReactNode[] = [
                    <Tooltip 
                        title={formatMessage({ id: 'app.notice.cancelPipeline' })}
                        key="delete"
                    >
                        <span>
                            <Popconfirm
                                title={formatMessage({ id: 'app.notice.cancelPipeline' })}
                                description={formatMessage({ id: 'app.notice.cancelPipeline.description' })}
                                onConfirm={() => dispatch(stopPipelineAsync({runId: item._id, op: 'cancel'}))}
                                okText={formatMessage({ id: 'app.notice.cancelPipelineOk' })}
                                cancelText={formatMessage({ id: 'app.notice.dontCancelPipeline' })}
                                okType="danger"
                                zIndex={1200}
                                disabled={!canCancel}
                            >
                                <MyButton
                                    type="primary"
                                    shape="circle"
                                    icon={<MdOutlineDeleteForever />}
                                    size="small"
                                    className="pipeline-action-button"
                                    disabled={!canCancel}
                                />
                            </Popconfirm>
                        </span>
                    </Tooltip>,
                ];

                if(canResume){
                    actions.push(
                        <Tooltip 
                            title={formatMessage({ id: 'app.notice.resumePipeline' })}
                            key="resume"
                        >
                            <span>
                                <MyButton
                                    type="primary"
                                    shape="circle"
                                    onClick={() => dispatch(resumePipelineAsync(item._id))}
                                    icon={<GrResume />}
                                    size="small"
                                    className="pipeline-action-button resume"
                                />
                            </span>
                        </Tooltip>
                    );
                } else if(canPause){
                    actions.push(
                        <Tooltip 
                            title={formatMessage({ id: 'app.notice.pausePipeline' })}
                            key="pause"
                        >
                            <span>
                                <MyButton
                                    type="primary"
                                    shape="circle"
                                    onClick={() => dispatch(stopPipelineAsync({runId: item._id, op: 'pause'}))}
                                    icon={<GrPause />}
                                    size="small"
                                    className="pipeline-action-button pause"
                                />
                            </span>
                        </Tooltip>
                    );
                }

                return (
                    <List.Item
                        actions={ actions }
                    >
                        <List.Item.Meta
                            avatar={
                                <Tooltip title={getPipelineStateTooltip(item)}>
                                    <div
                                        className="pipeline-state-icon"
                                        // Unfortunatelly, we can't use the onClick event on the List.Item.Meta whole component
                                        // so we have to attach handlers to icon, title and description
                                        onClick={() => setPreviewTaskId(item._id)}
                                    >
                                        { icons[state] }
                                    </div>
                                </Tooltip>
                            }
                            title={
                                <div 
                                    className="pipeline-title"
                                    onClick={() => setPreviewTaskId(item._id)}
                                >
                                    { item.name }
                                </div>
                            }
                            description={
                                <div 
                                    className="pipeline-descrption"
                                    onClick={() => setPreviewTaskId(item._id)}
                                >
                                    <span>{item.description}</span>
                                    <Tag color="blue">
                                        {formatMessage({ id: 'app.notice.tasks.number' }, { total: item.tasks.length })}
                                    </Tag>
                                </div>
                            }
                        />
                    </List.Item>
                );
            }}
            className="pipelines-list"
        />
    );
};

export default HeaderPipelinesComponent;

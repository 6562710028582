import type { Facebook } from '../../../../../../common/routes/channels/facebook';

import { message, Popconfirm } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MyButton from '@/components/basic/button';
import MyCheckBox from '@/components/basic/checkbox';
import MyInput from '@/components/basic/input';
import MySelect from '@/components/basic/select';
import MyForm from '@/components/core/form';
import { LocaleFormatter, useLocale } from '@/locales';
import {
	subscribeFacebookWebhookAsync,
	unsubscribeFacebookWebhookAsync,
} from '@/stores/channels.action';

import { Facebook as F } from '../../../../../../common/channels/facebook';
import SubscribeForm from './subscibeForm';

interface Props {
	record: Facebook.WebhooksRouter.ListResponse['data'][number];
	isAdmin: boolean;
	onDeleteWebhook: (id: string) => void;
}

const AppActions: React.FC<Props> = ({ record, isAdmin, onDeleteWebhook }) => {
	const { formatMessage } = useLocale();
	const dispatch = useDispatch();

	const [form] = MyForm.useForm();
	const [appSecret, setAppSecret] = useState<string>('');
	const [subscriptionObject, setSubscriptionObject] =
		useState<F.Webhooks.SubscriptionObject | null>(null);
	const [subscriptionFields, setSubscriptionFields] = useState<string[]>([]);

	const resetFields = () => {
		setAppSecret('');
		setSubscriptionObject(null);
		setSubscriptionFields([]);
		form.resetFields();
	};

	const onSubscribe = async () => {
		await form.validateFields();

		if (subscriptionObject) {
			const success = await dispatch(
				subscribeFacebookWebhookAsync({
					id: record._id,
					appId: record.appId,
					verifyToken: record.verifyToken,
					appSecret,
					object: subscriptionObject,
					fields: subscriptionFields as F.Webhooks.ISubscriptionObjectFields[typeof subscriptionObject],
				})
			);

			if (success) {
				message.success(
					formatMessage({ id: 'facebook.webhooks.subscribe.success' })
				);
			}

			resetFields();
		}
	};

	const onUnsubscribe = async () => {
		await form.validateFields();

		const unsubscribeBody: Facebook.WebhooksRouter.UnsubscribeBody = {
			appId: record.appId,
			appSecret,
		};

		if (subscriptionObject) {
			unsubscribeBody.object = subscriptionObject;
		}

		await dispatch(
			unsubscribeFacebookWebhookAsync({
				id: record._id,
				...unsubscribeBody,
			})
		);

		message.success(
			formatMessage({ id: 'facebook.webhooks.unsubscribe.success' })
		);
		resetFields();
	};

	if (!isAdmin) return null;

	return (
		<div className="webhooks-table-actions">
			<Popconfirm
				title={formatMessage({
					id: 'facebook.subscribeWebhookModal.title',
				})}
				description={
					<div className="webhooks-actions-modal-container">
						<div className="webhooks-actions-modal-description">
							{formatMessage({
								id: 'facebook.subscribeWebhookModal.description',
							})}
						</div>
						<MyForm
							layout="vertical"
							form={form}
							scrollToFirstError
						>
							<MyForm.Item
								name="App Secret"
								label="App Secret"
								rules={[{ required: true }]}
							>
								<MyInput
									value={appSecret}
									onChange={(e) =>
										setAppSecret(e.target.value)
									}
								/>
							</MyForm.Item>
							<SubscribeForm
								subscriptionObject={subscriptionObject}
								setSubscriptionObject={setSubscriptionObject}
								subscriptionFields={subscriptionFields}
								setSubscriptionFields={setSubscriptionFields}
							/>
						</MyForm>
					</div>
				}
				okText={formatMessage({ id: 'global.subscribe' })}
				onConfirm={onSubscribe}
				onCancel={resetFields}
				overlayStyle={{
					maxHeight: '80vh',
					overflowY: 'auto',
					zIndex: 1200,
				}}
				destroyTooltipOnHide
			>
				<MyButton type="primary" size="small">
					<LocaleFormatter id="global.subscribe" />
				</MyButton>
			</Popconfirm>
			<Popconfirm
				title={formatMessage({
					id: 'facebook.unsubscribeWebhookModal.title',
				})}
				description={
					<div className="webhooks-actions-modal-container">
						<div className="webhooks-actions-modal-description">
							{formatMessage({
								id: 'facebook.unsubscribeWebhookModal.description',
							})}
						</div>
						<MyForm
							layout="vertical"
							form={form}
							scrollToFirstError
						>
							<MyForm.Item
								name="App Secret"
								label="App Secret"
								rules={[{ required: true }]}
							>
								<MyInput
									value={appSecret}
									onChange={(e) =>
										setAppSecret(e.target.value)
									}
								/>
							</MyForm.Item>
							<MyForm.Item
								name="Subscription Type (optional)"
								label="Subscription Type"
								extra={
									<LocaleFormatter id="facebook.unsubscribeWebhookModal.subscriptionTypeExtra" />
								}
							>
								<MySelect
									value={subscriptionObject}
									onChange={setSubscriptionObject}
									dropdownStyle={{ zIndex: 1210 }}
									options={Object.values(
										F.Webhooks.SubscriptionObject
									).map((value) => ({
										label: value,
										value,
									}))}
								/>
							</MyForm.Item>
						</MyForm>
					</div>
				}
				okText={formatMessage({ id: 'global.unsubscribe' })}
				onConfirm={onUnsubscribe}
				onCancel={resetFields}
				destroyTooltipOnHide
			>
				<MyButton type="primary" size="small">
					<LocaleFormatter id="global.unsubscribe" />
				</MyButton>
			</Popconfirm>
			<Popconfirm
				title={formatMessage({
					id: 'facebook.deleteWebhookAppModal.title',
				})}
				description={formatMessage({
					id: 'facebook.deleteWebhookAppModal.description',
				})}
				onConfirm={() => onDeleteWebhook(record._id)}
				okText={formatMessage({ id: 'global.delete' })}
				okButtonProps={{ danger: true }}
				zIndex={1200}
			>
				<MyButton type="primary" size="small" danger>
					<LocaleFormatter id="global.delete" />
				</MyButton>
			</Popconfirm>
		</div>
	);
};

export default AppActions;

import type { EllipsisConfig } from 'antd/es/typography/Base';
import type { ParagraphProps } from 'antd/es/typography/Paragraph';

import { Tooltip, Typography } from 'antd';
import React, { useState } from 'react';

const { Paragraph } = Typography;

interface Props extends ParagraphProps {
    ellipsis?: EllipsisConfig;
}

const TooltipParagraph: React.FC<Props> = ({ children, ellipsis, ...props }) => {
    const [truncated, setTruncated] = useState(false);

    return (
        <Tooltip title={truncated ? children : undefined}>
            <Paragraph {...props} ellipsis={{ ...ellipsis, onEllipsis: setTruncated }}>
                <>{children}</>
            </Paragraph>
        </Tooltip>
    );
};

export default TooltipParagraph;

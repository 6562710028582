import { Events as T } from '../../../common/events';
import EventEmitter from 'events';
import { BACKEND_URL } from '@/environment';

/** Period to check last event time */
const CHECK_STUCK_INTERVAL_MSEC = 60_000;
/** If last event was more than this period ago, consider stream failed */
const MAX_EVENTS_PERIOD_MSEC = 60_000; // Twice server ping period

export default class Events extends EventEmitter implements ServerEvents {

    private source: EventSource | null = null;
    /** Timestamp of latest event received */
    private lastEventTime: number = 0;
    /** Handle of interval to check last event time */
    private checkStuckInterval: ReturnType<typeof setInterval> | null = null;
    /** Is stream connection connected and alive */
    connected: boolean = false;

    /**
     * Connect to events stream. 
     * Important: Ensure to have authentication cookie set before calling this method!
     */
    connect(){
        this.disconnect();
        this.setupEventSource();
        this.checkStuckInterval = setInterval(this.checkStuck.bind(this), CHECK_STUCK_INTERVAL_MSEC);
    }

    private setupEventSource(){
        this.source = new EventSource(
            `${BACKEND_URL}/events/stream`,
            {withCredentials: true}
        );
        this.source.addEventListener('open', this.onSourceOpen.bind(this));
        this.source.addEventListener('error', this.onSourceError.bind(this));
        this.source.addEventListener('message', this.onSourceEvent.bind(this));
    }

    disconnect(){
        if(null !== this.checkStuckInterval){
            clearInterval(this.checkStuckInterval);
            this.checkStuckInterval = null;
        }
        this.destroyEventSource();
    }

    private destroyEventSource(){
        if(this.source){
            this.source.close();
            this.source.removeEventListener('open', this.onSourceEvent.bind(this));
            this.source.removeEventListener('error', this.onSourceError.bind(this));
            this.source.removeEventListener('message', this.onSourceEvent.bind(this));
            this.source = null;
            this.connected = false;
        }
    }

    /** Simple helper to add one listener to more than one event */
    addMultiListener(events: T.Name[], listener: (data: any) => any): ServerEvents {
        for(const event of events){
            this.addListener(event, listener);
        }
        return this;
    }

    /** Opposite to addMultiListener() */
    removeMultiListener(events: T.Name[], listener: (data: any) => any): ServerEvents {
        for(const event of events){
            this.removeListener(event, listener);
        }
        return this;
    }

    private onSourceOpen(openEvent: Event){
        this.connected = true;
        this.emit(LifecycleEvent.STREAM_OPEN);
        console.debug('EventSource open:', openEvent);
    }

    private onSourceError(failEvent: Event){
        this.connected = false;
        this.emit(LifecycleEvent.STREAM_FAILED);
        console.warn(`EventSource failed:`, failEvent);
    }

    private onSourceEvent(serverEvent: MessageEvent){
        this.lastEventTime = Date.now();
        if(!this.connected){
            this.connected = true;
        }
        if(T.PING_MSG === serverEvent.data){
            return;
        }
        try {
            const {event, data} = JSON.parse(serverEvent.data);
            if(typeof event === 'string'){
                console.debug('Received server-sent event:', event, data);
                this.emit(event, data);
            } else {
                console.error('Malformed event: No "event" field inside event data or it not a string type');
            }
        } catch(error){
            console.error('Error parsing event data JSON:', error);
        }
    }

    private checkStuck(){
        if(Date.now() - this.lastEventTime > MAX_EVENTS_PERIOD_MSEC){
            this.connected = false;
            this.emit(LifecycleEvent.STREAM_FAILED);
            console.warn('EventSource failed: Detected too long no events period. Re-creating event source');
            this.destroyEventSource();
            this.setupEventSource();
        }
    }
    
}

export enum LifecycleEvent {
    STREAM_OPEN = 'stream_open',
    STREAM_FAILED = 'stream_failed',
}

export interface ServerEvents extends NodeJS.EventEmitter {
    addListener(event: LifecycleEvent, listener: () => any): this;
    addListener<E extends T.Name = T.Name>(event: E, listener: (data: T.EventNameDataMap[E]) => any): this;
    removeListener(event: LifecycleEvent, listener: () => any): this;
    removeListener<E extends T.Name = T.Name>(event: E, listener: (data: T.EventNameDataMap[E]) => any): this;
    connect(): void;
    disconnect(): void;
}


import type { FC } from 'react';

import './index.less';

import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import { LocaleFormatter } from '@/locales';
import { loadInstancesAsync } from '@/stores/instances.action';

import { Permission } from '../../../../common/permissions';
import AddInstanceModal from './AddInstanceModal';

const InstancesPage: FC = () => {
    const dispatch = useDispatch();
    const { instances } = useSelector((state) => state.instances);
    const [addInstanceModalVisible, setAddInstanceModalVisible] = useState(false);

    useEffect(() => {
        dispatch(loadInstancesAsync());
    }, []);

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="instances.title" />
                </h1>
                <div className="title-actions">
                    <PermissionGate permissions={[Permission.manageInstances]}>
                        <MyButton type="primary" onClick={() => setAddInstanceModalVisible(true)}>
                            <LocaleFormatter id="instances.addInstance" />
                        </MyButton>
                    </PermissionGate>
                </div>
            </div>
            <div className="content">
                {instances.map((instance) => (
                    <Card key={instance._id} title={instance._id} className="card" bordered={false} hoverable>
                        <p>
                            Customers count: {instance.customersCount}
                        </p>
                        <p>
                            URL: <a href={instance.url}>{instance.url}</a>
                        </p>
                    </Card>
                ))}
            </div>

            <AddInstanceModal visible={addInstanceModalVisible} onCancel={() => setAddInstanceModalVisible(false)} />
        </div>
    );
};

export default InstancesPage;

import type { Instances } from '../../../common/routes/instances';

import { request } from './request';

export const apiGetInstances = () => {
    return request<Instances.ListResponse>('get', '/instances');
};

export const apiAddInstance = (id: string, url: string) => {
    const params: Instances.AddBody = { id, url };

    return request<Instances.AddResponse>('post', '/instances', params);
};

import type { Customers } from '../../../../common/routes/customers';
import type { RJSFValidationError, Widget } from '@rjsf/utils';

import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import './index.less';

import { Form as RJSFForm } from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { message } from 'antd';
import React, { useEffect } from 'react';
import AceEditor from 'react-ace';
import classNames from 'classnames';

export type CodeModes = 'json' | 'yaml';

const CodeComponent: Widget = (props) => {
    return (
        <AceEditor
            mode={props.options.mode as CodeModes}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            readOnly={props.disabled}
            placeholder={props.placeholder}
            setOptions={{ useWorker: false }}
            theme="textmate"
            focus={true}
            className="add-ace-editor"
            width="100%"
            height="auto"
            fontSize={16}
        />
    );
};

interface Props {
    schema: Customers.Setup.SchemaResponse;
    formData: Record<string, any>;
    formRef: any;
    onChange: (data: Record<string, any>) => void;
    schemaPrefix?: string;
    canEdit?: boolean;
    className?: string;
}

const JSONEditor: React.FC<Props> = ({ schema, formRef, onChange, formData, canEdit = true, schemaPrefix, className }) => {
    const getUiSchema = () => {
        const uiSchema: Record<string, any> = schemaPrefix ? { [schemaPrefix]: {} } : {};

        if (schema.properties) {
            const properties = schemaPrefix ? schema.properties[schemaPrefix].properties : schema.properties;

            if (properties) {
                for (const property of Object.entries(properties)) {
                    const [name, value]: [string, any] = property;

                    if (['json', 'yaml'].includes(value._codeFormat)) {
                        const rule = {
                            'ui:widget': 'code',
                            'ui:options': {
                                mode: value._codeFormat,
                                disabled: !canEdit,
                            },
                        };

                        if (schemaPrefix) {
                            uiSchema[schemaPrefix][name] = rule;
                        } else {
                            uiSchema[name] = rule;
                        }
                    }
                }
            }
        }

        return uiSchema;
    };

    const onError = (e: RJSFValidationError[]) => {
        message.error(e[0].message);
    };

    const validateCustomCodeComponent = () => {
        const uiSchema = getUiSchema();

        if (Object.keys(uiSchema).length) {
            for (const [name, value] of Object.entries(uiSchema)) {
                if (value['ui:widget'] === 'code' && value['ui:options'].mode === 'json') {
                    const value = schemaPrefix ? formData[schemaPrefix][name] : formData[name];

                    try {
                        // 1. check if the field is required
                        if (schema.required?.includes(name)) {
                            if (!value) {
                                message.error(`must have required property '${name}'`);

                                return false;
                            }
                        }

                        // 2. check if the field is valid JSON
                        JSON.parse(value);
                    } catch (e) {
                        message.error(`Error on field ${name}: Invalid JSON`);

                        return false;
                    }
                }
            }
        }

        return true;
    };

    const validate = () => {
        if (!validateCustomCodeComponent()) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (formRef.current) {
            /* Exported function to validate RJSFForm */
            formRef.current.validate = validate;
        }
    }, [formRef, formData]);

    return (
        <RJSFForm
            validator={validator}
            schema={schema}
            uiSchema={getUiSchema()}
            widgets={{ code: CodeComponent }}
            formData={formData}
            className={classNames('JSONEditor-container', className)}
            onChange={(e) => onChange(e.formData)}
            onError={onError}
            ref={formRef}
            readonly={!canEdit}
        />
    );
};

export default JSONEditor;

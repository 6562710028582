import type { Partners as P } from '../../../common/partners';
import type { Partners } from '../../../common/routes/partners';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

const initialState: PartnersState = {
    partners: [],
    total: 0,
    myPartner: null,
};

const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {
        setPartners(state, action: PayloadAction<Partners.ListResponse>) {
            state.partners = action.payload.data;
            state.total = action.payload.total;
        },
        addPartner(state, action: PayloadAction<Partners.PostResponse>) {
            state.partners.push(action.payload);
            state.total++;
        },
        editPartner(state, action: PayloadAction<Partners.PatchResponse>) {
            const index = state.partners.findIndex((partner) => partner._id === action.payload._id);

            state.partners[index] = action.payload;
        },
        setMyPartner(state, action: PayloadAction<Partners.GetResponse>) {
            state.myPartner = action.payload;
        },
    },
});

export const { setPartners, addPartner, editPartner, setMyPartner } = partnersSlice.actions;

export default partnersSlice.reducer;

export type PartnersState = {
    partners: Partners.ListResponse['data'];
    total: number;
    myPartner: P.Partner | null;
};

export const enUS_customers = {
    'customers.title': 'Customers',
    'customers.table.header.id': 'ID',
    'customers.table.header.instance': 'Instance',
    'customers.table.header.partner': 'Partner',
    'customers.table.header.customerId': 'Customer ID',
    'customers.table.header.user': 'User',
    'customers.table.header.userId': 'User ID',
    'customers.table.header.hosts': 'Hosts',
    'customers.table.header.customerRoles': 'Customer Roles',
    'customers.table.header.requestMessage': 'Request Message',
    'customers.table.header.status': 'Status',
    'customers.table.header.actions': 'Actions',
    'customers.table.header.createdAt': 'Created',
    'customers.table.header.firstAccessAt': 'First Access',
    'customers.table.header.expiresAt': 'Expires',
    'customers.table.header.number': '#',
    'customers.add.title': 'Setup new Customer',
    'customers.config.title': 'Customer Configuration',
    'customers.config.title.edit': 'Edit Customer Configuration',
    'customers.config.invalidJSON': 'Invalid JSON',
    'customers.config.unsavedChanges.title': 'Unsaved Changes',
    'customers.config.unsavedChanges.description':
    'You have unsaved changes in the configuration, are you sure you want to cancel?',
    'customer.config.save.success': 'Customer configuration has been saved successfully',
    'customer.config.secretValues.warning':
    'Secret values were removed from the configuration, and will be overwritten with the existing values in case of edit',
    'customers.move.title': 'Move Customer - {customerId}',
	'customers.move.moveCustomer': 'Move <b>{customerId}</b> to <b>{instanceId}</b> {upgrade}',
	'customers.move.disallowed': 'Not Allowed',
	'customers.move.permissionRequired': 'Permission required',
	'customers.move.permissionRequiredDescription':
		'You do not have {permission} permission to move this customer',
    'customers.add.success': 'Customer setup has started',
    'customers.add.partner': 'Partner',
    'customers.add.instance': 'Select Instance',
    'customers.access.selectCustomer': 'Select Customer',
    'customers.access.selectCustomerRequired': 'Please select Customer',
    'customers.access.title': 'Customer Access',
    'customers.access.discard': 'Discard',
    'customers.access.revoke': 'Revoke',
    'customers.access.edit': 'Edit',
    'customers.access.requestAccess': 'Request Access',
    'customers.access.loginInto': 'Login into {customerId}',
    'customers.access.grantAccess': 'Grant Access',
    'customers.access.editAccess': 'Edit Access',
    'customers.access.roles': 'Roles',
    'customers.access.rolesRequired': 'Please enter Roles',
    'customers.access.channels': 'Filter by Channel',
    'customers.access.selectActions': 'Select Actions',
    'customers.access.selectUser': 'Select User',
    'customers.access.chats': 'Chats',
    'customers.access.searchChat': 'Search for Chat...',
    'customers.access.chatsFilter': 'Filter by Chat',
    'customers.access.requestMessage': 'Request Message',
    'customers.access.requestMessage.placeholder': 'Enter request message',
    'customers.access.expiresAt': 'Expires At',
    'customers.access.expiresAtRequired': 'Please enter expiry date',
    'customers.access.revokeModalTitle': 'Revoke Access Request',
    'customers.access.revokeModalDescription': 'Are you sure you want to revoke Access Request?',
    'customers.access.approveModalExpires': 'Request will expire at:',
    'customers.access.rejectModalTitle': 'Reject Access Request',
    'customers.access.rejectModalDescription': 'Are you sure you want to reject Access Request?',
    'customers.access.rejectModalReason': 'Reason for rejection (optional)',
    'customers.access.approveModalTitle': 'Approve Access Request',
    'customers.access.approveModalDescription': 'Are you sure you want to approve Access Request?',
    'customers.access.discardModalTitle': 'Discard Access Request',
    'customers.access.discardModalDescription': 'Are you sure you want to discard Access Request?',
    'customers.access.requestAccessUpdateSuccess': 'Access request updated successfully',
    'customers.access.requestAccessCreateSuccess': 'Access request created successfully',
    'customers.access.noChatsFound': 'No chats found',
    'customers.access.filters.grantNumber': 'Case Number',
    'customers.access.filters.customer': 'Customer',
    'customers.access.filters.search': 'Search',
    'customers.access.filters.instance': 'Instance',
    'customers.access.filters.partner': 'Partner',
    'customers.access.filters.status': 'Status',
    'customers.access.filters.showExpired': 'Show Expired',
    'customers.access.filters.clearAllFilters': 'Clear All Filters',
    'customers.access.segments.my_access': 'My Access',
    'customers.access.segments.manage': 'Manage',
    'customer.access.request.useExisting': 'Please use one of the existing ones:',
    'customer.access.request.matchTitle': 'Matched Active Access',
};

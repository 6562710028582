import type { FC } from 'react';

import { AiOutlineLoading } from 'react-icons/ai';
import { Badge, List, Popover, Spin, Tabs, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as NoticeSvg } from '@/assets/header/notice.svg';
import { LocaleFormatter, useLocale } from '@/locales';
import { loadNotificationsAsync, loadPipelinesAsync, resumePipelineAsync } from '@/stores/notification.action';

import PipelinePreviewModal from './PipelinePreviewModal';
import HeaderPipelinesComponent from './pipelines';

const loadingIcon = <AiOutlineLoading className="loading-icon" />;

const HeaderNoticeComponent: FC = () => {
    const dispatch = useDispatch();
    const { notifications, pipelines } = useSelector((state) => state.notification);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModalVisible, setPreviewModalVisible] = useState(false);
    const [previewPipelineId, setPreviewPipelineId] = useState<string>('');
    const { noticeCount } = useSelector((state) => state.user);
    const { formatMessage } = useLocale();

    // loads the notices belonging to logged in user
    // and sets loading flag in-process
    const getNotice = async () => {
        setLoading(true);
        Promise.all([dispatch(loadNotificationsAsync({})), dispatch(loadPipelinesAsync())])
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getNotice();
    }, []);

    useEffect(() => {
        setPreviewModalVisible(!!previewPipelineId);
    }, [previewPipelineId]);

    useEffect(() => {
        // close the modal if the task is removed from the list (done, or removed)
        if (previewModalVisible && !pipelines?.find((p) => p._id === previewPipelineId)) {
            setPreviewPipelineId('');
        }
    }, [pipelines, previewModalVisible]);

    const tabs = (
        <div className="notice-tabs-wrapper">
            <Spin tip={<LocaleFormatter id="global.tips.loading" />} indicator={loadingIcon} spinning={loading}>
                <Tabs
                    defaultActiveKey={ pipelines.length ? '2' : '1' }
                    items={[
                        {
                            key: '1',
                            label: `${formatMessage({ id: 'app.notice.notifications' })} (${notifications.length})`,
                            children: (
                                <List
                                    dataSource={notifications}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.titleFallback}
                                                description={item.bodyFallback}
                                            />
                                        </List.Item>
                                    )}
                                />
                            ),
                        },
                        {
                            key: '2',
                            label: `${formatMessage({ id: 'app.notice.running' })} (${pipelines.length})`,
                            children: (
                                <HeaderPipelinesComponent pipelines={pipelines} setPreviewTaskId={setPreviewPipelineId} />
                            ),
                        },
                    ]}
                />
            </Spin>
        </div>
    );

    return (
        <Popover
            content={tabs}
            overlayClassName="bg-2"
            placement="bottomRight"
            trigger={['click']}
            open={visible}
            onOpenChange={(v) => setVisible(v)}
            overlayStyle={{
                width: 336,
            }}
        >
            <Tooltip
                title={formatMessage({
                    id: 'global.tips.theme.noticeTooltip',
                })}
            >
                <Badge count={noticeCount} overflowCount={999}>
                    <span className="notice" id="notice-center">
                        <NoticeSvg className="anticon" />
                    </span>
                </Badge>
            </Tooltip>

            <PipelinePreviewModal
                visible={previewModalVisible}
                onClose={() => {
                    setPreviewModalVisible(false);
                    setPreviewPipelineId('');
                }}
                pipeline={pipelines.find((p) => p._id === previewPipelineId)!}
                onResumePipeline={() => dispatch(resumePipelineAsync(previewPipelineId))}
            />
        </Popover>
    );
};

export default HeaderNoticeComponent;

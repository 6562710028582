import type { Customers } from '../../../common/routes/customers';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

const initialState: CustomersState = {
    customers: [],
    total: 0,
    schema: {},
};

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setCustomers(
            state,
            action: PayloadAction<{
                data: Customers.ListResponse;
            }>,
        ) {
            state.customers = state.customers.concat(action.payload.data.customers);
            state.total = action.payload.data.total;
        },
        unsetCustomers(state) {
            state.customers = [];
            state.total = 0;
        },
        setSchema(state, action: PayloadAction<Customers.Setup.SchemaResponse>) {
            state.schema = action.payload;
        },
    },
});

export const { setCustomers, unsetCustomers, setSchema } = customersSlice.actions;

export default customersSlice.reducer;

export type CustomersState = {
    customers: Customers.ListResponse['customers'];
    total: Customers.ListResponse['total'];
    schema: Customers.Setup.SchemaResponse;
};

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import languageEN from './locales/en-US/i18n.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: languageEN,
        },
        lng: localStorage.getItem('locale') || 'en_US',
        debug: import.meta.env.DEV,
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            bindI18n: 'languageChanged loaded',
            bindI18nStore: 'added removed',
            nsMode: 'default'
        }
    });

export default i18n;

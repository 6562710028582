import type { MessageDescriptorId } from '@/locales';
import type { FC } from 'react';

import './index.less';

import { Alert, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { omit, pick } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import MyInput from '@/components/basic/input';
import JSONEditor from '@/components/basic/JSONEditor';
import PermissionGate from '@/components/basic/permission-gate';
import MyForm from '@/components/core/form';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { LocaleFormatter, useLocale } from '@/locales';
import NotAuthorized from '@/routes/NotAuthorized';
import {
    addPartnerAsync,
    editPartnerAsync,
    loadCustomersSetupValuesAsync,
    loadSinglePartnerAsync,
} from '@/stores/partners.action';
import { hasPermissions } from '@/stores/user.action';

import { Permission } from '../../../../common/permissions';

interface Props {
    action: 'add' | 'edit' | 'view';
    title: MessageDescriptorId;
}

const PartnerForm: FC<Props> = ({ action = 'view', title }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { formatMessage } = useLocale();
    const { myPartner } = useSelector((state) => state.partners);
    const { user } = useSelector((state) => state.user);
    const { partnerId } = useParams();
    const [formInstance] = useForm();
    const JSONEditorRef = useRef<any>(null);

    const [schema, setSchema] = useState<any>({});
    const [customersSetupValues, setCustomersSetupValues] = useState<any>({});

    const isMyPartner = myPartner?._id === user?.partnerId;
    const hasPermission = dispatch(hasPermissions([Permission.partnersManage]));

    useAsyncEffect(async () => {
        if (action === 'add') return;

        if (partnerId && partnerId !== myPartner?._id) {
            dispatch(loadSinglePartnerAsync(partnerId));
        }
    }, [partnerId, myPartner, action]);

    useAsyncEffect(async () => {
        const schema = await dispatch(loadCustomersSetupValuesAsync());

        setSchema(schema);
    }, []);

    const goToMyPartnerProfile = (edit?: boolean) => {
        navigate(`/partners/${myPartner?._id}` + (edit ? '/edit' : ''));
    };

    const onPartnerAddOrEdit = async () => {
        const form = await formInstance.validateFields();
        const valid = JSONEditorRef.current.validate();

        if (form && valid) {
            const addBody = { ...form };
            const editBody = { ...pick(myPartner, ['name']), ...omit(form, ['_id']) };

            if (customersSetupValues) {
                editBody.customersSetupValues = customersSetupValues;
                addBody.customersSetupValues = customersSetupValues;
            }

            let success = false;

            try {
                if (action === 'add') {
                    success = await dispatch(addPartnerAsync(addBody));
                } else if (action === 'edit' && myPartner) {
                    success = await dispatch(
                        editPartnerAsync({
                            partnerId: myPartner?._id,
                            body: editBody,
                        }),
                    );
                }

                if (success) {
                    const successMessage = action === 'add' ? 'partners.add.success' : 'partners.edit.success';

                    message.success(formatMessage({ id: successMessage }), 10);
                    navigate('/partners');
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onCancel = () => {
        goToMyPartnerProfile();
        formInstance.setFieldsValue({ name: myPartner?.name });
        setCustomersSetupValues(myPartner?.customersSetupValues);
    };

    useEffect(() => {
        if (action !== 'add') {
            formInstance.setFieldsValue({ name: myPartner?.name, _id: myPartner?._id });
        }

        if (myPartner?.customersSetupValues) {
            setCustomersSetupValues(myPartner?.customersSetupValues);
        }
    }, [myPartner, action]);

    /* Instead of route-level permissions gate, there is an additional check for user.partnerId here */
    if (!isMyPartner && !hasPermission) {
        return <NotAuthorized />;
    }

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id={title} />
                </h1>
                <div className="title-actions">
                    <PermissionGate permissions={[Permission.partnersManage]}>
                        {['edit', 'add'].includes(action) ? (
                            <>
                                <MyButton type="default" onClick={onCancel}>
                                    <LocaleFormatter id="global.cancel" />
                                </MyButton>
                                <MyButton type="primary" onClick={onPartnerAddOrEdit}>
                                    <LocaleFormatter id={action === 'add' ? 'global.create' : 'global.edit'} />
                                </MyButton>
                            </>
                        ) : (
                            <MyButton type="primary" onClick={() => goToMyPartnerProfile(true)}>
                                <LocaleFormatter id="partners.profile.edit" />
                            </MyButton>
                        )}
                    </PermissionGate>
                </div>
            </div>
            <div className="partners-content">
                {action === 'add' ? null : action === 'edit' ? (
                    <Alert message="Edit mode" description="You can modify the profile." type="info" showIcon />
                ) : (
                    <Alert
                        message="Read-only mode"
                        description="Only users with permission can edit."
                        type="warning"
                        showIcon
                    />
                )}
                <MyForm form={formInstance} layout="vertical">
                    <MyForm.Item label={<LocaleFormatter id="partners.profile.id" />} name="_id">
                        <MyInput readOnly={!['add'].includes(action)} disabled={action === 'edit'} />
                    </MyForm.Item>
                    <MyForm.Item label={<LocaleFormatter id="partners.profile.name" />} name="name">
                        <MyInput readOnly={!['edit', 'add'].includes(action)} />
                    </MyForm.Item>
                </MyForm>
                {schema && (
                    <JSONEditor
                        schema={schema}
                        schemaPrefix="v1"
                        formData={customersSetupValues}
                        onChange={setCustomersSetupValues}
                        formRef={JSONEditorRef}
                        canEdit={['edit', 'add'].includes(action)}
                    />
                )}
            </div>
        </div>
    );
};

export default PartnerForm;

import type { Facebook } from '../../../../../common/routes/channels';
import type { ColumnsType } from 'antd/es/table';
import type { FC } from 'react';

import './index.less';

import { Popconfirm, Tag } from 'antd';
import Table from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import { LocaleFormatter, useLocale } from '@/locales';
import { deleteFacebookUserTokenAsync, loadFacebookUserTokensAsync } from '@/stores/channels.action';

import { Permission } from '../../../../../common/permissions';
import AddEditTokenModal from './AddEditTokenModal';

const UserTokensPage: FC = () => {
    const dispatch = useDispatch();
    const { facebook } = useSelector((state) => state.channels);
    const { formatMessage } = useLocale();
    const [addTokenModalVisible, setAddTokenModalVisible] = useState(false);
    const [editTokenId, setEditTokenId] = useState<string | undefined>(undefined);

    const { total, query, data } = facebook.tokens;

    const onPageChange = (page: number) => {
        if (page * query.limit > data.length && data.length < total) {
            dispatch(loadFacebookUserTokensAsync({ skip: page * query.limit }));
        }
    };

    const onEditToken = (id: string) => {
        setEditTokenId(id);
        setAddTokenModalVisible(true);
    };

    const onCloseModal = () => {
        setAddTokenModalVisible(false);
        setEditTokenId(undefined);
    };

    useEffect(() => {
        dispatch(loadFacebookUserTokensAsync({ reset: true }));
    }, []);

    const columns: ColumnsType<Facebook.Tokens.GetResponse> = [
        {
            title: formatMessage({ id: 'facebook.tableHeader.name' }),
            dataIndex: 'name',
        },
        {
            title: formatMessage({ id: 'facebook.tableHeader.scopes' }),
            dataIndex: 'scopes',
            render: (scopes: string[]) => (
                <div>
                    {scopes.map((scope) => (
                        <Tag key={scope} color="#546BD6">
                            {scope}
                        </Tag>
                    ))}
                </div>
            ),
        },
        {
            title: formatMessage({ id: 'facebook.tableHeader.notes' }),
            dataIndex: 'notes',
        },
        {
            title: formatMessage({ id: 'facebook.tableHeader.expiresAt' }),
            dataIndex: 'expires_at',
            render: (expiresAt: number) => {
                if (!expiresAt || expiresAt === 0) {
                    return <LocaleFormatter id="global.infininity" />;
                }

                return dayjs(expiresAt).format('lll');
            },
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            render: (id: string) => (
                <div className="user-tokens-table-actions">
                    <PermissionGate permissions={[Permission.manageFacebookUserTokens]}>
                        <MyButton type="link" onClick={() => onEditToken(id)}>
                            <LocaleFormatter id="global.edit" />
                        </MyButton>
                    </PermissionGate>
                    <PermissionGate permissions={[Permission.manageFacebookUserTokens]}>
                        <Popconfirm
                            title={formatMessage({ id: 'facebook.deleteTokenModal.title' })}
                            description={formatMessage({ id: 'facebook.deleteTokenModal.description' })}
                            onConfirm={() => dispatch(deleteFacebookUserTokenAsync(id))}
                            okText={formatMessage({ id: 'global.delete' })}
                            zIndex={1200}
                        >
                            <MyButton type="link">
                                <LocaleFormatter id="global.delete" />
                            </MyButton>
                        </Popconfirm>
                    </PermissionGate>
                </div>
            ),
        },
    ];

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <span className="subtitle">Facebook</span>
                    <LocaleFormatter id="facebook.tokens.title" />
                </h1>
                <div className="title-actions">
                    <PermissionGate permissions={[Permission.manageFacebookUserTokens]}>
                        <MyButton type="primary" onClick={() => setAddTokenModalVisible(true)}>
                            <LocaleFormatter id="facebook.tokens.addToken" />
                        </MyButton>
                    </PermissionGate>
                </div>
            </div>
            <div className="content">
                <Table
                    columns={columns}
                    dataSource={facebook.tokens.data}
                    rowKey="_id"
                    className="user-tokens-table"
                    pagination={{
                        total,
                        pageSize: query.limit,
                        showSizeChanger: false,
                        showTotal: (total) => `Total: ${total} tokens`,
                        onChange: onPageChange,
                    }}
                />
            </div>

            <AddEditTokenModal visible={addTokenModalVisible} onCancel={onCloseModal} editTokenId={editTokenId} />
        </div>
    );
};

export default UserTokensPage;

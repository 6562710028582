import whatsappSVG from '../assets/logo/whatsapp.svg';

export default function getIconFromName(name: string) {
    switch (name) {
        case 'whatsapp':
            return whatsappSVG;
        default:
            return null;
    }
}

import type { Permission } from '../../../common/permissions';
import type { FC } from 'react';
import type { RouteProps } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import { hasPermissions } from '@/stores/user.action';

import NotAuthorized from './NotAuthorized';

export type PrivateRouteProps = RouteProps & {
    /** permissions */
    permissions?: Permission[];
};

const PrivateRoute: FC<PrivateRouteProps> = (props) => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    let allow = false;

    if (user) {
        if (props.permissions) {
            allow = Boolean(dispatch(hasPermissions(props.permissions)));
        } else {
            allow = true;
        }
    }

    return allow ? (props.element as React.ReactElement) : <NotAuthorized />;
};

export default PrivateRoute;

import type { Partners as Partners } from '../../../../../common/partners';
import type { Instances } from '../../../../../common/routes/instances';
import type { Partners as Routes } from '../../../../../common/routes/partners';

import { Divider } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MyButton from '@/components/basic/button';
import MyInput from '@/components/basic/input';
import MySelect from '@/components/basic/select';
import { useLocale } from '@/locales';
import { searchPartnersAsync } from '@/stores/partners.action';

interface Props {
	instances: Instances.ListResponse;
	instance: string;
	setInstance: (instance: string) => void;
	partner: string;
	setPartner: (partner: string) => void;
	partnerPlaceholder: Partners.Partner | null;
	search: string;
	setSearch: (search: string) => void;
	onClearAllFilters: () => void;
}

const ListFilters: React.FC<Props> = ({
	instances,
	instance,
	setInstance,
	partner,
	setPartner,
	partnerPlaceholder,
	search,
	setSearch,
	onClearAllFilters,
}) => {
	const dispatch = useDispatch();
	const { formatMessage } = useLocale();
	const { partners: partnersRouteList } = useSelector(
		(state) => state.partners
	);
	const [partners, setPartners] =
		useState<Routes.ListResponse['data']>(partnersRouteList);

	useEffect(() => {
		/* When navigating from /partners, partners list will be available.
		 * When direct url navigation, with partnerId as searchParam, we need to
		 * pre-select partner from the results
		 */
		if (partner && partnerPlaceholder && partners.length === 0) {
			setPartners([partnerPlaceholder]);
		}
	}, [partner, partnerPlaceholder, partners]);

	const fetchPartners = debounce(async (query: string) => {
		const partners = await dispatch(searchPartnersAsync(query));

		if (partners) {
			setPartners(partners);
		}
	}, 500);

	const onPartnerSearch = (query: string) => {
		if (query.length >= 2) {
			fetchPartners(query);
		}
	};

	return (
		<div className="customers-filters">
			<div className="customers-filters-box">
				<label className='text-2'>
					{formatMessage({ id: 'customers.access.filters.search' })}
				</label>
				<MyInput
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					size="middle"
					placeholder="Search..."
					className="access-filters-select-wrapper"
				/>
			</div>
			<Divider type="vertical" />
			<div className="customers-filters-box">
				<label className='text-2'>
					{formatMessage({
						id: 'customers.access.filters.instance',
					})}
				</label>
				<MySelect
					value={instance}
					onChange={setInstance}
					size="middle"
					options={instances.map(({ _id }) => ({
						label: _id,
						value: _id,
					}))}
					filterOption={false}
					placeholder="Instance"
					className="access-filters-select-wrapper"
					allowClear
					showSearch
				/>
			</div>
			<Divider type="vertical" />
			<div className="customers-filters-box">
				<label className='text-2'>
					{formatMessage({ id: 'customers.access.filters.partner' })}
				</label>
				<MySelect
					value={partner}
					onChange={setPartner}
					onSearch={onPartnerSearch}
					size="middle"
					options={partners.map(({ name, _id }) => ({
						label: name,
						value: _id,
					}))}
					filterOption={false}
					placeholder="Partner Name"
					allowClear
					showSearch
					className="access-filters-select-wrapper"
				/>
			</div>
			<Divider type="vertical" />
			<MyButton onClick={onClearAllFilters} danger>
				{formatMessage({
					id: 'customers.access.filters.clearAllFilters',
				})}
			</MyButton>
		</div>
	);
};

export default ListFilters;

import type { Partners } from '../../../common/routes/partners';

import { request } from './request';

export const apiGetPartners = (search?: string) => {
    return request<Partners.ListResponse>('get', '/partners', { search });
};

export const apiGetSinglePartner = (partnerId: string) => {
    return request<Partners.GetResponse>('get', `/partners/${partnerId}`);
};

export const apiPatchPartner = (partner: Partners.PatchBody, partnerId: string) => {
    return request<Partners.PatchResponse>('patch', `/partners/${partnerId}`, partner);
};

export const apiPostPartner = (partner: Partners.PostBody) => {
    return request<Partners.PostResponse>('post', '/partners', partner);
};

export const apiGetCustomersSetupValues = () => {
    return request<Partners.GetCustomersSetupValuesSchemaResponse>('get', '/partners/customers-setup-values-schema');
};

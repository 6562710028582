import { deletePipeline, upsertPipeline } from '@/stores/notification.store';
import { Events } from '../../../../common/events';
import { ServerEvents } from '../emitter';
import store from '@/stores';

export function attach(events: ServerEvents){
    events.addListener(Events.Name.pipelineCreated, onCreated);
    events.addListener(Events.Name.pipelineUpdated, onUpdated);
}

export function detach(events: ServerEvents){
    events.removeListener(Events.Name.pipelineCreated, onCreated);
    events.removeListener(Events.Name.pipelineUpdated, onUpdated);
}

function onCreated(data: Events.PipelineCreatedData){
    store.dispatch(upsertPipeline(data));
}

function onUpdated(data: Events.PipelineUpdatedData){
    if(data.completedAt || data.cancelled){
        store.dispatch(deletePipeline(data._id));
    } else {
        store.dispatch(upsertPipeline(data));
    }
}

import type { FC } from 'react';

import './index.less';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import { Alert, List, message, Popconfirm } from 'antd';
import { useState } from 'react';
import AceEditor from 'react-ace';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import useUnsavedChangesBrowserWarning from '@/hooks/useUnsavedChangesWarning';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import { loadCustomerConfigAsync, updateCustomerConfigAsync } from '@/stores/customers.action';
import { hasPermissions } from '@/stores/user.action';

import { Permission } from '../../../../common/permissions';

const ConfigCustomerPage: FC = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useLocale();
    const { instanceId, customerId } = useParams<{ instanceId: string; customerId: string }>();
    const [isEdit, setIsEdit] = useState(false);

    const [config, setConfig] = useState<any>({});
    const [editorState, setEditorState] = useState<string>('');
    const [showSecretProperties, setShowSecretProperties] = useState(false);

    const hasFullAccess = dispatch(hasPermissions([Permission.customersConfigsFullAccess]));
    const hasChanges = JSON.stringify(config.config, null, 2) !== editorState;

    useUnsavedChangesBrowserWarning(hasChanges);

    useAsyncEffect(async () => {
        if (instanceId && customerId) {
            const config = await dispatch(loadCustomerConfigAsync(instanceId, customerId));

            setConfig(config);
            setEditorState(JSON.stringify(config?.config, null, 2));
        } else {
            history.push('/customers');
        }
    }, [instanceId, customerId]);

    const onCancel = () => {
        setIsEdit(false);
        setEditorState(JSON.stringify(config?.config, null, 2));
    };

    const onSave = async () => {
        try {
            JSON.parse(editorState);
        } catch (e) {
            message.error(formatMessage({ id: 'customers.config.invalidJSON' }));

            return;
        }

        if (instanceId && customerId && config) {
            const success = await dispatch(updateCustomerConfigAsync(instanceId, customerId, JSON.parse(editorState)));

            if (success) {
                message.success(formatMessage({ id: 'customer.config.save.success' }));
                setIsEdit(false);
                setEditorState(JSON.stringify(config?.config, null, 2));

                history.push('/customers');
            }
        }
    };

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="customers.config.title" />
                </h1>
            </div>
            <div className="add-content config">
                {!hasFullAccess && (
                    <Alert
                        message={formatMessage({ id: 'global.warning' })}
                        description={
                            <div className="config-warning-container">
                                <span className="description">
                                    {formatMessage({ id: 'customer.config.secretValues.warning' })}
                                </span>
                                {showSecretProperties && (
                                    <List
                                        size="small"
                                        bordered
                                        dataSource={config.secretProperties}
                                        renderItem={(item) => <List.Item>{item}</List.Item>}
                                    />
                                )}
                            </div>
                        }
                        action={
                            <MyButton
                                size="small"
                                type="primary"
                                onClick={() => setShowSecretProperties(!showSecretProperties)}
                            >
                                {showSecretProperties
                                    ? formatMessage({ id: 'global.hide' })
                                    : formatMessage({ id: 'global.show' })}
                            </MyButton>
                        }
                        type="warning"
                        showIcon
                    />
                )}
                {isEdit ? (
                    <Alert message="Edit mode" description="You can edit this configuration" type="info" showIcon />
                ) : (
                    <Alert
                        message="Read-only mode"
                        description="You can edit this configuration only if you have full access to customer configurations"
                        type="warning"
                        showIcon
                    />
                )}
                <AceEditor
                    mode={'json'}
                    name={'json-editor'}
                    value={editorState}
                    onChange={setEditorState}
                    setOptions={{ useWorker: false }}
                    theme="textmate"
                    focus={true}
                    className="add-ace-editor"
                    width="100%"
                    height="100%"
                    readOnly={!isEdit}
                    fontSize={16}
                />
                <PermissionGate permissions={[Permission.customersConfigsUpdate]}>
                    {isEdit ? (
                        <div className="buttons-container">
                            {hasChanges ? (
                                <Popconfirm
                                    title={formatMessage({ id: 'customers.config.unsavedChanges.title' })}
                                    description={formatMessage({ id: 'customers.config.unsavedChanges.description' })}
                                    onConfirm={onCancel}
                                    okText={formatMessage({ id: 'global.confirm' })}
                                    zIndex={1200}
                                >
                                    <MyButton type="default" size="large" className="add-submit-button">
                                        <LocaleFormatter id="global.cancel" />
                                    </MyButton>
                                </Popconfirm>
                            ) : (
                                <MyButton type="default" size="large" className="add-submit-button" onClick={onCancel}>
                                    <LocaleFormatter id="global.cancel" />
                                </MyButton>
                            )}
                            <MyButton type="primary" size="large" className="add-submit-button" onClick={onSave}>
                                <LocaleFormatter id="global.save" />
                            </MyButton>
                        </div>
                    ) : (
                        <MyButton
                            type="primary"
                            size="large"
                            className="add-submit-button"
                            onClick={() => setIsEdit(true)}
                        >
                            <LocaleFormatter id="global.edit" />
                        </MyButton>
                    )}
                </PermissionGate>
            </div>
        </div>
    );
};

export default ConfigCustomerPage;

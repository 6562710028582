import type { Facebook as F } from '../../../../../common/channels/facebook';
import type { Facebook } from '../../../../../common/routes/channels';

import './index.less';

import { message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { type FunctionComponent, useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';

import MyButton from '@/components/basic/button';
import MyInput from '@/components/basic/input';
import MySwitch from '@/components/basic/switch';
import MyForm from '@/components/core/form';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import { addFacebookWebhookAsync } from '@/stores/channels.action';

import SubscribeForm from './components/subscibeForm';

const AddFacebookWHRouterAppPage: FunctionComponent = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useLocale();
    const [form] = useForm();

    const [id, setId] = useState<string>('');
    const [appId, setAppId] = useState<string>('');
    const [appSecret, setAppSecret] = useState<string>('');
    const [subscribe, setSubscribe] = useState(false);
    const [subscriptionObject, setSubscriptionObject] = useState<F.Webhooks.SubscriptionObject | null>(null);
    const [subscriptionFields, setSubscriptionFields] = useState<string[]>([]);

    const onSubmit = async () => {
        await form.validateFields();

        const newWebhook: Facebook.WebhooksRouter.AddBody = {
            _id: id,
            appId,
            appSecret,
        };

        if (subscribe && subscriptionObject) {
            newWebhook.subscribe = {
                object: subscriptionObject,
                fields: subscriptionFields as F.Webhooks.ISubscriptionObjectFields[typeof subscriptionObject],
                appId,
            };
        }

        const success = await dispatch(addFacebookWebhookAsync(newWebhook));
        const successMessage = formatMessage({ id: 'facebook.webhooks.add.success' });

        if (success) {
            form.resetFields();
            message.success(successMessage);
            history.push('/channels/facebook/webhooks');
        }
    };

    useEffect(() => {
        if (!subscribe) {
            setSubscriptionObject(null);
            setSubscriptionFields([]);
            form.resetFields(['Subscription Type', 'Subscription Fields']);
        }
    }, [subscribe])

    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id="facebook.webhooks.add" />
                </h1>
                <div className="title-actions"></div>
            </div>
            <div className="add-webhook-content">
                <MyForm layout="vertical" form={form} scrollToFirstError>
                    <MyForm.Item
                        name="ID"
                        label="ID"
                        rules={[{ required: true }]}
                        extra={<LocaleFormatter id="facebook.webhooks.add.idExtra" />}
                    >
                        <MyInput value={id} onChange={(e) => setId(e.target.value)} />
                    </MyForm.Item>
                    <MyForm.Item 
                        name="Facebook App ID" 
                        label="Facebook App ID" 
                        rules={[{ required: true }]}
                        extra={<LocaleFormatter id="facebook.webhooks.add.appIdExtra" />}
                    >
                        <MyInput value={appId} onChange={(e) => setAppId(e.target.value)} />
                    </MyForm.Item>
                    <MyForm.Item name="App Secret" label="App Secret" rules={[{ required: true }]}>
                        <MyInput value={appSecret} onChange={(e) => setAppSecret(e.target.value)} />
                    </MyForm.Item>
                    <MyForm.Item
                        name="Subscribe"
                        label="Subscribe"
                        extra={<LocaleFormatter id="facebook.webhooks.add.subscribeExtra" />}
                    >
                        <MySwitch checked={subscribe} onChange={setSubscribe} />
                    </MyForm.Item>
                    <SubscribeForm
                        required={subscribe}
                        subscriptionObject={subscriptionObject}
                        setSubscriptionObject={setSubscriptionObject}
                        subscriptionFields={subscriptionFields}
                        setSubscriptionFields={setSubscriptionFields}
                    />
                </MyForm>
                <MyButton type="primary" size="large" onClick={onSubmit} className="add-webhook-submit-button">
                    <LocaleFormatter id="facebook.webhooks.add" />
                </MyButton>
            </div>
        </div>
    );
};

export default AddFacebookWHRouterAppPage;

import type { Instances } from '../../../common/routes/instances';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';

const initialState: InstancesState = {
    loaded: false,
    instances: [],
    selectedInstanceId: '',
};

const instancesSlice = createSlice({
    name: 'instances',
    initialState,
    reducers: {
        setInstances(state, action: PayloadAction<Instances.ListResponse>) {
            state.instances = action.payload;
            state.loaded = true;
        },
        addInstance(state, action: PayloadAction<Instances.AddResponse>) {
            state.instances.push({
                ...omit(action.payload, 'customers'),
                customersCount: action.payload.customers?.length ?? 0,
            });
        },
        unsetInstances(state) {
            state.instances = [];
            state.loaded = false;
        },
        selectInstanceId(state, action: PayloadAction<string | undefined>) {
            state.selectedInstanceId = action.payload;
        },
    },
});

export const { setInstances, addInstance, unsetInstances, selectInstanceId } = instancesSlice.actions;

export default instancesSlice.reducer;

export type InstancesState = {
    loaded: boolean,
    instances: Instances.ListResponse;
    selectedInstanceId: string | undefined;
};

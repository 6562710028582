
export namespace Customer {
    /**
     * Customer texterchat project information - data kept by texterchat instance
     */
    export interface Info {
        /** Customer ID */
        _id: string;
        /**
         * Customer hosts. At least one host must always exist
         */
        hosts: [string, ...string[]];
        /**
         * Current instance where customer is hosted
         */
        instance: string;
        /**
         * Is customer disabled
         */
        disabled?: boolean;
        /**
         * Currently in process of upgrade/migration
         */
        upgrading?: boolean;
        attributes?: Record<string, string | string[]>;
    }


    export type AccessGrant<
        IsJSON extends boolean = false, 
        // Passing exact type as parameter because it external package (mongodb) that we shouldn't install here
        ObjectId = any
    > = {
        _id: IsJSON extends true ? string : ObjectId;
        /**
         * A human-readable unique number for this grant
         */
        number: number;
        /**
         * Customer to grant access to
         */
        customerId: string;
        /**
         * texterchat-admin user ID to grant access for
         */
        uid: string; 
        /**
         * Roles grant to user in customer environment
         */
        customerRoles: string[];
        /**
         * Particular chats to grant access to in customer environment
         */
        customerChatsAccess?: Array<{
            // We not using here types from texterchat-common on purpose
            // because they may vary from texterchat version to version
            // and we communicate via separately versioned external API
            /**
             * Channel name (e.g.: WhatsApp, Messger, etc)
             */
            name: string;
            /**
             * Channels account ID
             */
            accountId: string;
            /**
             * Chat ID
             */
            id?: string;
            /**
             * Chat access type
             */
            type: AccessChatType[];
        }>;
        /**
         * User ID of user created in customer environment, if created already.
         */
        customerUid?: string;
        /**
         * Record creation datetime
         */
        created: IsJSON extends false ? Date : string;
        /**
         * Optional message from requester for approving admin
         */
        requestMessage?: string;
        /**
         * Record last update datetime
         */
        updated?: IsJSON extends false ? Date : string;
        /**
         * Datetime when customer was first accessed by user
         */
        firstAccess?: IsJSON extends false ? Date : string;
    } & ({
        status: AccessGrantStatus.approved;
        /**
         * When access was approved
         */
        approved: IsJSON extends false ? Date : string;
        /**
         * When this grant expires
         */
        expires: IsJSON extends false ? Date : string;
        rejected?: undefined;
        reasonMessage?: undefined;
    } | {
        status: AccessGrantStatus.rejected;
        /**
         * When access was rejected
         */
        rejected: IsJSON extends false ? Date : string;
        reasonMessage?: string;
        approved?: undefined;
        expires?: undefined;
    } | {
        status: AccessGrantStatus.pending | AccessGrantStatus.discarded | AccessGrantStatus.revoked;
        approved?: undefined;
        expires?: undefined;
        rejected?: undefined;
        reasonMessage?: undefined;
    });

    export type Metadata = {
        /**
         * Customer ID
         */
        _id: string;
        /**
         * Names of projects on Google Cloud Platform that are owned by this customer 
         */
        gcpProjects?: string[];
    }

    export enum AccessGrantStatus {
        /**
         * Requested by requester but not yet reviewed by admin
         */
        pending = 'pending',
        /**
         * Granted by admin or automatically if requester is admin himself
         */
        approved = 'approved',
        /**
         * Rejected by admin
         */
        rejected = 'rejected',
        /**
         * Discarded by requester himself
         */
        discarded = 'discarded',
        /**
         * Revoked by admin after being approved
         */
        revoked = 'revoked',
    }

    export enum AccessChatType {
        list = 'list',
        view = 'view',
        manage = 'manage',
        send = 'send',
    }
}

import type { Instances } from '../../../common/routes/instances';
import type { Dispatch } from '@reduxjs/toolkit';

import { apiAddInstance, apiGetInstances } from '../api/instances.api';
import { addInstance, selectInstanceId, setInstances } from './instances.store';
import { createAsyncAction } from './utils';

export const loadInstancesAsync = () => {
    return async (dispatch: Dispatch) => {
        const { data } = await apiGetInstances();

        if (data) {
            dispatch(setInstances(data));
        }
    };
};

export const addInstanceAsync = createAsyncAction<Instances.AddBody>((payload) => {
    return async (dispatch) => {
        const { data } = await apiAddInstance(payload.id, payload.url);

        if (data) {
            dispatch(addInstance(data));

            return true;
        }

        return false;
    };
});

export const selectInstanceIdSync = (instanceId?: string) => {
    return (dispatch: Dispatch) => {
        dispatch(selectInstanceId(instanceId));
    };
};

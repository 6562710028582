import type { Permission } from '../../../common/permissions';
import type { IUser } from '../../../common/users/interfaces';
import type { LoginParams } from '../api/user.api';
import type { UserState } from '@/interface/user/user';
import type { Dispatch } from '@reduxjs/toolkit';

import { apiGetSession, apiLogin, apiLogout } from '../api/user.api';
import { setUserSession, unsetUserSession } from './user.store';
import { createAsyncAction } from './utils';

export const loadCurrentUserAsync = () => {
    return async (dispatch: Dispatch): Promise<IUser | null> => {
        const { data } = await apiGetSession();

        if (data?.user) {
            dispatch(setUserSession(data));

            return data.user;
        }

        return null;
    };
};

export const loginAsync = createAsyncAction<LoginParams, boolean>((payload) => {
    return async (dispatch) => {
        const { data } = await apiLogin(payload);

        if (data) {
            if (data.completed) {
                dispatch(setUserSession(data));

                return true;
            }

            // TODO: handle 2FA
        }

        return false;
    };
});

export const logoutAsync = () => {
    return async (dispatch: Dispatch) => {
        const { status } = await apiLogout();

        if (status === 200) {
            dispatch(unsetUserSession());

            return true;
        }

        return false;
    };
};

export const hasPermissions = (permissions: Permission[]) => {
    return (_: Dispatch, getState: () => { user: UserState }) => {
        const { user } = getState().user;

        if (user) {
            return permissions.every((permission) => user.permissions.includes(permission));
        }

        return false;
    };
};

import type { Locale, UserState } from '@/interface/user/user';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

import { IUser, UserSession } from '../../../common/users/interfaces';

const initialState: UserState = {
    noticeCount: 0,
    locale: (localStorage.getItem('locale')! || 'en_US') as Locale,
    menuList: [],
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserSession(state, action: PayloadAction<{user: IUser, session: UserSession<true>}>) {
            Object.assign(state, action.payload);
        },
        unsetUserSession(state) {
            state.user = undefined;
            state.session = undefined;
        },
        setLocale(state, action: PayloadAction<Locale>) {
            state.locale = action.payload;
        },
    },
});

export const { setUserSession, unsetUserSession, setLocale } = userSlice.actions;

export default userSlice.reducer;

import { useEffect } from 'react';
import { redirect, useLocation } from 'react-router-dom';

import UserForm from './userForm';

const EditUserPage: React.FunctionComponent = () => {
    const { state } = useLocation();

    useEffect(() => {
        if (!state) {
            redirect('/users');
        }
    }, [state]);

    return <UserForm user={state?.user} title="users.edit" />;
};

export default EditUserPage;

import type { Facebook } from '../../../common/routes/channels';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

const initialState: ChannelsState = {
    facebook: {
        tokens: {
            data: [],
            total: 0,
            query: {
                skip: 0,
                limit: 20,
            },
        },
    },
};

const channelsSlice = createSlice({
    name: 'channels',
    initialState,
    reducers: {
        setFacebookUserTokens(
            state,
            action: PayloadAction<{
                data: Facebook.Tokens.ListResponse;
                query: ChannelsState['facebook']['tokens']['query'];
            }>,
        ) {
            state.facebook.tokens = {
                data: state.facebook.tokens.data.concat(action.payload.data.data),
                total: action.payload.data.total,
                query: action.payload.query,
            };
        },
        deleteFacebookUserToken(state, action: PayloadAction<string>) {
            state.facebook.tokens.data = state.facebook.tokens.data.filter((token) => token._id !== action.payload);
            state.facebook.tokens.total--;
        },
        editFacebookUserToken(state, action: PayloadAction<{ id: string; notes: string | null }>) {
            state.facebook.tokens.data = state.facebook.tokens.data.map((token) => {
                if (token._id === action.payload.id) {
                    return {
                        ...token,
                        notes: action.payload.notes,
                    };
                }

                return token;
            });
        },
        unsetFacebookUserTokens(state) {
            state.facebook.tokens = {
                ...initialState.facebook.tokens,
            };
        },
    },
});

export const {
    setFacebookUserTokens,
    deleteFacebookUserToken,
    editFacebookUserToken,
    unsetFacebookUserTokens,
} = channelsSlice.actions;

export default channelsSlice.reducer;

export type ChannelsState = {
    facebook: {
        tokens: {
            data: Facebook.Tokens.ListResponse['data'];
            total: Facebook.Tokens.ListResponse['total'];
            query: {
                skip: number;
                limit: number;
            };
        };
    };
};

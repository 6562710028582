export const enUS_permissionRole = {
    'app.permission.role.name': 'Role Name',
    'app.permission.role.code': 'Role Code',
    'app.permission.role.status': 'Status',
    'app.permission.role.status.all': 'All',
    'app.permission.role.status.enabled': 'Enabled',
    'app.permission.role.status.disabled': 'Disabled',
    'app.permission.role.nameRequired': 'Please input the role name',
    'app.permission.role.codeRequired': 'Please input the role code',
    'app.permission.role.statusRequired': 'Please select the enabled status',
};

import type { Customers } from '../../../../common/routes/customers';
import type { LoadCustomersAsyncParams } from '@/stores/customers.action';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import type { FC } from 'react';

import './index.less';

import { Table } from 'antd';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineSetting, AiOutlineSwap } from 'react-icons/ai';
import { MdOutlineKey } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';
import { loadCustomersAsync } from '@/stores/customers.action';
import { loadInstancesAsync } from '@/stores/instances.action';
import { hasPermissions } from '@/stores/user.action';

import { Permission } from '../../../../common/permissions';
import ListFilters from './components/ListFilters';

const CustomersPage: FC = () => {
	const dispatch = useDispatch();
	const { instances } = useSelector((state) => state.instances);
	const { customers, total } = useSelector((state) => state.customers);
	const { user } = useSelector((state) => state.user);
	const { formatMessage } = useLocale();
	const [searchParams] = useSearchParams();

	const firstRender = useRef(true);

	/* Filter States */
	const [instanceId, setInstanceId] = useState<string>(
		searchParams.get('instanceId') || ''
	);
	const [partnerId, setPartnerId] = useState<string>(
		searchParams.get('partnerId') || ''
	);
	const [search, setSearch] = useState<string>('');
	const [page, setPage] = useState<number>(1);
	const [limit, setLimit] = useState<number>(10);
	const [sortBy, setSortBy] = useState<Customers.ListQuery['sortField']>();
	const [orderBy, setOrderBy] =
		useState<Customers.ListQuery['sortDirection']>();

	const isAdmin = dispatch(
		hasPermissions([Permission.customersAccessGrantApprove])
	);

	useEffect(() => {
		dispatch(loadInstancesAsync());
	}, []);

	const loadData = (query: LoadCustomersAsyncParams) => {
		dispatch(loadCustomersAsync(query));
	};

	const loadDataDebounced = useCallback(
		debounce(async (query: LoadCustomersAsyncParams) => {
			loadData(query);
		}, 500),
		[]
	);

	const onClearAllFilters = () => {
		setSearch('');
		setPartnerId('');
		setInstanceId('');
	};

	const generateQuery = (skip?: number) => {
		const query: Customers.ListQuery = {
			skip,
		};

		if (sortBy) {
			query.sortField = sortBy;
			query.sortDirection = orderBy;
		}

		if (search) {
			query.search = search;
		}

		if (instanceId) {
			query.instanceId = instanceId;
		}

		if (partnerId) {
			query.partnerId = partnerId;
		}

		return query;
	};

	useEffect(() => {
		loadData(generateQuery());
	}, [instanceId, partnerId, sortBy, orderBy]);

	useEffect(() => {
		/* Don't want to run in on mount */
		if (firstRender.current) {
			firstRender.current = false;

			return;
		}

		loadDataDebounced(generateQuery());
	}, [search]);

	const onTableChange = (
		_: any,
		__: any,
		sorter:
			| SorterResult<Customers.ListResponse['customers'][number]>
			| SorterResult<Customers.ListResponse['customers'][number]>[]
	) => {
		if (sorter instanceof Array) return;

		const field = sorter.field as Customers.ListQuery['sortField'];

		setSortBy(field);
		setOrderBy(sorter.order === 'ascend' ? 'asc' : 'desc');
	};

	const columns: ColumnsType<Customers.ListResponse['customers'][number]> = [
		{
			title: formatMessage({ id: 'customers.table.header.id' }),
			dataIndex: '_id',
			sorter: true,
		},
		{
			title: formatMessage({ id: 'customers.table.header.instance' }),
			dataIndex: 'instance',
			sorter: true,
		},
		...(!user?.partnerId
			? [
					{
						title: formatMessage({
							id: 'customers.table.header.partner',
						}),
						dataIndex: 'partner',
						render: (
							partner: Customers.ListResponse['customers'][number]['partner']
						) => partner?.name,
					},
			  ]
			: []),
		{
			title: formatMessage({ id: 'customers.table.header.hosts' }),
			dataIndex: 'hosts',
			render: (hosts: string[]) => {
				return hosts.join(', ');
			},
		},
		{
			title: 'Actions',
			render: (customer: Customers.ListResponse['customers'][number]) => (
				<div className="table-actions">
					<PermissionGate permissions={[Permission.customersConfigsSafeAccess]}>
						<MyButton
							type="primary"
							onClick={() =>
								history.push(
									`/customers/config/${customer.instance}/${customer._id}`
								)
							}
							className="table-action"
							size="small"
							icon={<AiOutlineSetting />}
						>
							Configuration
						</MyButton>
					</PermissionGate>
					<PermissionGate permissions={[Permission.customersMigrateInstances]}>
						<MyButton
							type="primary"
							onClick={() =>
								history.push(
									`/customers/move/${customer.instance}/${customer._id}`
								)
							}
							className="table-action"
							size="small"
							icon={<AiOutlineSwap />}
						>
							Move
						</MyButton>
					</PermissionGate>
					<MyButton
						type="primary"
						onClick={() =>
							history.push('/customers/access/request', {
								grant: { customerId: customer._id },
							})
						}
						className="table-action"
						size="small"
						icon={<MdOutlineKey style={{ rotate: '-45deg' }} />}
					>
						<span>
							{isAdmin ? (
								<LocaleFormatter id="customers.access.grantAccess" />
							) : (
								<LocaleFormatter id="customers.access.requestAccess" />
							)}
						</span>
					</MyButton>
				</div>
			),
		},
	];

	return (
		<div className="container">
			<div className="title">
				<h1>
					<LocaleFormatter id="customers.title" />
				</h1>
				<div className="title-actions">
					<PermissionGate permissions={[Permission.setupCustomer]}>
						<MyButton
							type="primary"
							onClick={() => history.push('/customers/add')}
						>
							<LocaleFormatter id="customers.add.title" />
						</MyButton>
					</PermissionGate>
				</div>
			</div>
			<div className="customers-filters-box">
				<ListFilters
					instances={instances}
					instance={instanceId}
					setInstance={setInstanceId}
					partner={partnerId}
					setPartner={setPartnerId}
					partnerPlaceholder={customers?.[0]?.partner ?? null}
					search={search}
					setSearch={setSearch}
					onClearAllFilters={onClearAllFilters}
				/>
				<Table
					columns={columns}
					dataSource={customers}
					onChange={onTableChange}
					sortDirections={['descend', 'ascend']}
					showSorterTooltip={{
						placement: 'bottomRight',
					}}
					rowKey="_id"
					pagination={{
						total,
						pageSize: limit,
						current: page,
						showSizeChanger: false,
						showTotal: (total) => `Total: ${total} customers`,
						onChange: (page) => {
							setPage(page);

							if (page * limit > customers.length && customers.length < total) {
								const skip = (page - 1) * limit;

								dispatch(loadCustomersAsync(generateQuery(skip)));
							}
						},
					}}
				/>
			</div>
		</div>
	);
};

export default CustomersPage;

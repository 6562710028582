import type { IUser } from '../../../common/users/interfaces';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

interface UsersState {
    users: {
        [uid: string]: IUser;
    };
    total: number;
}

const initialState: UsersState = {
    users: {},
    total: 0,
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers(state, action: PayloadAction<{ data: IUser[]; total: number }>) {
            state.users = action.payload.data.reduce((acc, user) => {
                acc[user._id] = user;

                return acc;
            }, {} as UsersState['users']);
            state.total = action.payload.total;
        },
        addUser(state, action: PayloadAction<IUser>) {
            state.users[action.payload._id] = action.payload;
            state.total++;
        },
        updateUser(state, action: PayloadAction<IUser>) {
            state.users[action.payload._id] = action.payload;
        },
        deleteUser(state, action: PayloadAction<string>) {
            delete state.users[action.payload];
            state.total--;
        },
    },
});

export const { setUsers, addUser, deleteUser, updateUser } = usersSlice.actions;

export default usersSlice.reducer;

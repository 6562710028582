export const enUS_notice = {
    'app.notice.messages': `Messages`,
    'app.notice.news': `News`,
    'app.notice.tasks': `Tasks`,
    'app.notice.tasks.number': `{total} tasks`,
    'app.notice.notifications': `Notifications`,
    'app.notice.running': `Running pipelines`,
    'app.notice.stop': `Stop`,
    'app.notice.cancelPipeline': `Delete pipeline`,
    'app.notice.cancelPipelineOk': `Yes, Delete pipeline`,
    'app.notice.dontCancelPipeline': `No, Do not delete`,
    'app.notice.cancelPipeline.description': `Are you sure you want to irreversibly delete this pipeline?`,
    'app.notice.resumePipeline': `Resume`,
    'app.notice.pausePipeline': `Pause`,
    'app.notice.state': `State: {state}`,
    'app.notice.paused': 'Paused',
    'app.notice.pausedDescription': 'Pipeline is paused. Click "resume" button to start it from next task.',
};

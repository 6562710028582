import type { Facebook } from '../../../../../common/routes/channels';
import type { ColumnsType } from 'antd/es/table';
import type { FC } from 'react';

import './index.less';

import { Table } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import PermissionGate from '@/components/basic/permission-gate';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { LocaleFormatter, useLocale } from '@/locales';
import {
	deleteFacebookWebhookAsync,
	loadFacebookWebhooksAsync,
} from '@/stores/channels.action';
import { hasPermissions } from '@/stores/user.action';

import { Permission } from '../../../../../common/permissions';
import AppActions from './components/actions';

const WebhookRouterPage: FC = () => {
	const dispatch = useDispatch();
	const { formatMessage } = useLocale();
	const navigate = useNavigate();

	const [list, setList] = useState<
		Facebook.WebhooksRouter.ListResponse['data']
	>([]);
	const [total, setTotal] = useState<number>(0);
	const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);

	const canModify = dispatch(
		hasPermissions([Permission.facebookWebhooksRouterModify])
	);

	useAsyncEffect(async () => {
		const data = await dispatch(loadFacebookWebhooksAsync({ skip: 0, limit }));

		if (data) {
			setList(data.data);
			setTotal(data.total);
		}
	}, []);

	const onPageChange = async (page: number) => {
        setPage(page);

		if (page * limit > list.length && list.length < total) {
            const skip = (page - 1) * limit;
			const data = await dispatch(
				loadFacebookWebhooksAsync({ skip, limit })
			);

            if (data) {
                setTotal(data.total);

                if (skip) {
                    setList(data.data);
                } else {
                    setList([...list, ...data.data]);

                }
            }
		}
	};

	const onDeleteWebhook = async (id: string) => {
		const success = await dispatch(deleteFacebookWebhookAsync(id));

		if (success) {
			setList(list.filter((item) => item._id !== id));
		}
	};

	const columns: ColumnsType<
		Facebook.WebhooksRouter.ListResponse['data'][number]
	> = [
		{
			title: formatMessage({ id: 'facebook.tableHeader.id' }),
			dataIndex: '_id',
		},
		{
			title: formatMessage({ id: 'facebook.tableHeader.appId' }),
			dataIndex: 'appId',
		},
		{
			title: formatMessage({
				id: 'facebook.tableHeader.requestCount24h',
			}),
			dataIndex: 'requestCount24h',
			align: 'right',
		},
		{
			title: formatMessage({
				id: 'facebook.tableHeader.latestRequestTime',
			}),
			dataIndex: 'latestRequestTime',
			align: 'right',
			defaultSortOrder: 'descend',
			sorter: (a, b) => {
				if (!a.latestRequestTime) {
					return -1;
				}

				if (!b.latestRequestTime) {
					return 1;
				}

				return a.latestRequestTime - b.latestRequestTime;
			},
			sortDirections: ['descend', 'ascend'],
			render: (latestRequestTime?: number) => {
				if (!latestRequestTime || latestRequestTime === 0) {
					return <LocaleFormatter id="global.never" />;
				}

				return dayjs(latestRequestTime).format('lll');
			},
		},
		{
			title: 'Actions',
			dataIndex: '_id',
			render: (_: string, record) => (
				<AppActions
					record={record}
					isAdmin={canModify}
					onDeleteWebhook={onDeleteWebhook}
				/>
			),
		},
	];

	return (
		<div className="container">
			<div className="title">
				<h1>
					<span className="subtitle">Facebook</span>
					<LocaleFormatter id="facebook.webhooks.title" />
				</h1>
				<div className="title-actions">
					<PermissionGate
						permissions={[Permission.facebookWebhooksRouterAdd]}
					>
						<MyButton
							type="primary"
							onClick={() =>
								navigate('/channels/facebook/webhooks/add')
							}
						>
							<LocaleFormatter id="facebook.webhooks.add" />
						</MyButton>
					</PermissionGate>
				</div>
			</div>
			<div className="content">
				<Table
					columns={columns}
					dataSource={list}
					rowKey="_id"
					className="user-tokens-table"
					pagination={{
						total,
						pageSize: limit,
                        current: page,
						showSizeChanger: false,
						showTotal: (total) => `Total: ${total} webhooks`,
						onChange: onPageChange,
					}}
				/>
			</div>
		</div>
	);
};

export default WebhookRouterPage;

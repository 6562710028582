import type { FC } from 'react';
import type { MessageDescriptor } from 'react-intl';

import { FormattedMessage, useIntl } from 'react-intl';

import en_US from './en-US';

export const localeConfig = {
    en_US: en_US,
};

export type MessageDescriptorId = keyof typeof en_US;

interface Props extends MessageDescriptor {
    id: MessageDescriptorId;
    values?: Record<string, any>;
}

export const LocaleFormatter: FC<Props> = ({ ...props }) => {
    const notChildProps = { ...props, children: undefined };

    return <FormattedMessage {...notChildProps} id={props.id} />;
};

type FormatMessageProps = (descriptor: Props, values?: Record<string, any>) => string;

export const useLocale = () => {
    const { formatMessage: _formatMessage, ...rest } = useIntl();
    const formatMessage: FormatMessageProps = _formatMessage;

    return {
        ...rest,
        formatMessage,
    };
};

import { FC } from 'react';
import MyDatePicker from '@/components/basic/date-picker';
import { AccessGrants } from '../../../../common/routes/access-grants';
import dayjs, { Dayjs } from 'dayjs';

type ExpireDatePickerProps = {
    value?: Dayjs;
    onChange?: (date: Dayjs | null) => void;
    getPopupContainer?: (trigger: HTMLElement) => HTMLElement;
}

export const ExpireDatePicker: FC<ExpireDatePickerProps> = (props) => {

    const minuteStep = 15,
          maxDate = dayjs().add(AccessGrants.ACCESS_MAX_TTL_MS, 'ms');

    function isDisabledExactDatetime(current: dayjs.Dayjs) {
        return current < dayjs() || current > maxDate;
    }

    function isDisabledDay(current?: dayjs.Dayjs | null) {
        if(!current){
            return false;
        }
        return current < dayjs().startOf('day') || current > maxDate;
    }

    function getDisabledTime(currentDate: dayjs.Dayjs | null) {

        if(!currentDate){
            return {};
        }

        const dayBegin = currentDate.startOf('day');

        return {
            disabledHours: () => {
                const disabled: number[] = [];
                for(let i = 1; i <= 24; i++){
                    if(isDisabledExactDatetime(dayBegin.add(i, 'hour'))){
                        disabled.push(i);
                    }
                }
                return disabled;
            },
            disabledMinutes: (hours: number) => {
                const disabled: number[] = [];
                for(let i = 0; i <= 60; i += minuteStep){
                    if(isDisabledExactDatetime(dayBegin.add(hours, 'hour').add(i, 'minute'))){
                        disabled.push(i);
                    }
                }
                return disabled;
            },
        };
    }

    return (
        <div className="expire-date-picker">
            <MyDatePicker
                showTime={{
                    minuteStep,
                    showSecond: false,
                    format: 'HH:mm',
                    use12Hours: false,
                    hideDisabledOptions: true,
                }}
                format="MMM D, YYYY h:mm A"
                disabledDate={isDisabledDay}
                disabledTime={getDisabledTime}
                {...props}
            />
            <span className='expire-date-relative-time'>
                ({props.value && dayjs().to(props.value)})
            </span>
        </div>
    );
}
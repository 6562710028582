import { useSelector } from 'react-redux';

import UserForm from './userForm';

const MePage: React.FunctionComponent = () => {
    const { user } = useSelector((state) => state.user);

    return <UserForm user={user} title="users.profile" />;
};

export default MePage;

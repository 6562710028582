import type { FC } from 'react';

import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-github';
import './index.less';

import { message, Select } from 'antd';
import { pick } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import JSONEditor from '@/components/basic/JSONEditor';
import MySelect from '@/components/basic/select';
import MyForm from '@/components/core/form';
import { LocaleFormatter, useLocale } from '@/locales';
import {
	loadSetupSchemaAsync,
	setupCustomerAsync,
} from '@/stores/customers.action';
import {
	loadInstancesAsync,
	selectInstanceIdSync,
} from '@/stores/instances.action';
import { loadPartnersAsync } from '@/stores/partners.action';

const AddCustomerPage: FC = () => {
	const NO_PARTNER = '__none__';
	const dispatch = useDispatch();
	const { schema } = useSelector((state) => state.customers);
	const navigate = useNavigate();
	const { selectedInstanceId, instances, loaded } = useSelector(
		(state) => state.instances
	);
	const { user } = useSelector((state) => state.user);
	const { partners } = useSelector((state) => state.partners);
	const { formatMessage } = useLocale();
	const [partnerId, setPartnerId] = useState<string | undefined>();
	const [formData, setFormData] = useState<Record<string, any>>({});
	// https://github.com/rjsf-team/react-jsonschema-form/issues/2135#issuecomment-794408169
	const JSONEditorRef = useRef<any>(null);

	const onInstanceChange = (instanceId: string) => {
		dispatch(selectInstanceIdSync(instanceId));
	};

	useEffect(() => {
		if (!user?.partnerId && !partners.length) {
			dispatch(loadPartnersAsync());
		}
	}, []);

	useEffect(() => {
		if (!instances.length) {
			dispatch(loadInstancesAsync());
		}
	}, []);

	useEffect(() => {
		if (selectedInstanceId) {
			dispatch(loadSetupSchemaAsync(selectedInstanceId, partnerId));
		}
	}, [selectedInstanceId, partnerId]);

	// Reset fields that not in schema when schema changes
	useEffect(() => {
		if (schema) {
			setFormData(pick(formData, Object.keys(schema.properties ?? {})));
		}
	}, [schema]);

	const onSetupUser = async () => {
		const valid = JSONEditorRef.current.validate();

		if (valid && selectedInstanceId) {
			const success = Boolean(
				await dispatch(
					setupCustomerAsync(selectedInstanceId, {
						...formData,
						partnerId,
					})
				)
			);

			if (success) {
				message.success(
					formatMessage({ id: 'customers.add.success' }),
					10
				);
				navigate('/customers');
			}
		}
	};

	return (
		<div className="add-container">
			<div className="title">
				<h1>
					<LocaleFormatter id="customers.add.title" />
				</h1>
			</div>
			<div className="add-content">
				<MyForm layout="vertical" className="add-content-form">
					<MyForm.Item
						name="instance"
						label={<LocaleFormatter id="customers.add.instance" />}
					>
						<MySelect
							value={selectedInstanceId}
							onChange={onInstanceChange}
							loading={!loaded && !instances.length}
							className="select-instance"
							options={instances.map(({ _id }) => ({
								value: _id,
								label: _id,
							}))}
						/>
					</MyForm.Item>
					<MyForm.Item
						name="partner"
						label={<LocaleFormatter id="customers.add.partner" />}
						hidden={!!user?.partnerId || !partners.length}
					>
						<Select
							value={partnerId || NO_PARTNER}
							onChange={(value) => {
								setPartnerId(
									value === NO_PARTNER ? undefined : value
								);
							}}
							options={[
								{
									value: NO_PARTNER,
									label: formatMessage({ id: 'global.none' }),
								},
								...partners.map((partner) => ({
									value: partner._id,
									label: partner.name,
								})),
							]}
						/>
					</MyForm.Item>
					<JSONEditor
						schema={schema}
						formData={formData}
						onChange={setFormData}
						formRef={JSONEditorRef}
						className="add-customer-json-editor"
					/>
					<MyButton
						size="large"
						type="primary"
						onClick={onSetupUser}
						className="customers-add-submit-button"
					>
						<LocaleFormatter id="global.submit" />
					</MyButton>
				</MyForm>
			</div>
		</div>
	);
};

export default AddCustomerPage;

import type { AccessGrants } from '../../../common/routes/access-grants';

import { request } from './request';

export const apiGetAccessGrants = (query?: AccessGrants.ListQuery) => {
    return request<AccessGrants.ListResponse<true>>('get', '/access-grants', query);
};

export const apiPostAccessGrant = (data: AccessGrants.PostBody) => {
    return request<AccessGrants.PostResponse>('post', '/access-grants', data);
};

export const apiApproveAccessGrant = (id: string, expires: number) => {
    return request<AccessGrants.AdminActionResponse>('post', `/access-grants/${id}/admin-action`, {
        action: 'approve',
        expires,
    });
};

export const apiRejectAccessGrant = (id: string, reason?: string) => {
    return request<AccessGrants.AdminActionResponse>('post', `/access-grants/${id}/admin-action`, {
        action: 'reject',
        reason,
    });
};

export const apiRevokeAccessGrant = (id: string) => {
    return request<AccessGrants.AdminActionResponse>('post', `/access-grants/${id}/admin-action`, { action: 'revoke' });
};

export const apiPatchAccessGrant = (data: AccessGrants.PatchBody, id: string) => {
    return request<AccessGrants.PatchResponse>('patch', `/access-grants/${id}`, data);
};

export const apiGetAuthenticationUrl = (id: string) => {
    return request<AccessGrants.GetAuthenticationUrlResponse>('get', `/access-grants/${id}/authentication-url`);
};

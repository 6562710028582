export const enUS_instances = {
    'instances.title': 'Instances',
    'instances.addInstance': 'Add Instance',
    'instances.instanceId': 'ID',
    'instances.instanceUrl': 'URL',
    'instances.instanceIdPlaceholder': 'Enter ID',
    'instances.instanceUrlPlaceholder': 'Enter URL',
    'instances.instanceIdRequired': 'ID is required',
    'instances.instanceUrlRequired': 'URL is required',
};

import { Customer } from '../customer';
import { OffsetPaginatedQuery, OffsetPaginatedQueryResult } from './_includes';

export namespace AccessGrants {

    export type ListQuery<IsServer extends boolean = false> = OffsetPaginatedQuery<IsServer> & {
        /**
         * Exact unique number of access grant.
         * All other filters are ignored if this property is present.
         * For non-admin users would return only access grants created for this user.
         */
        number?: IsServer extends true ? string : number;
        customerId?: string;
        status?: Customer.AccessGrantStatus | Customer.AccessGrantStatus[];
        /**
         * Return also expired access grants
         */
        showExpired?: IsServer extends true ? 'true' | 'false' : boolean;
        /**
         * Sort by this field
         */
        sortField?: ListSortField;
        /**
         * Sort direction
         */
        sortDirection?: 'asc' | 'desc';
    } & ({
        /**
         * User ID to list access grants for. 
         * By default current user ID is used when user is not admin, and none when user is admin.
         */
        uid?: string;
        notUid?: never;
    } | {
        /**
         * User ID to exclude from listing access grants for.
         */
        notUid?: string;
        uid?: never;
    });

    export type ListResponse<IsJSON extends boolean = true> = OffsetPaginatedQueryResult<Customer.AccessGrant<IsJSON> & {
        customerInstances?: Array<{
            /**
             * Instance ID
             */
            _id: string;
        }>;
    }>;

    export type PostBody<IsJSON extends boolean = false> = Pick<
        Customer.AccessGrant<IsJSON>,
        'customerId' | 'customerRoles' | 'customerChatsAccess' | 'requestMessage'
    > & {
        /**
         * Datetime when access expires. Timestamp in milliseconds.
         * This property should be present if request made by admin - because it gets approved automatically.
         */
        expires?: number;
        /**
         * User ID to grant access for. By default current user ID is used.
         */
        uid?: string;
    };

    export type PostResponse<IsJSON extends boolean = true> = Customer.AccessGrant<IsJSON>;

    /**
     * At least one property must be present in request body
     */
    export type PatchBody<IsJSON extends boolean = false> = Partial<
        Omit<
            PostBody<IsJSON>, 'customerId'
        > & {
            status?: Customer.AccessGrantStatus.discarded;
        }
    >;

    export type PatchResponse<IsJSON extends boolean = true> = Customer.AccessGrant<IsJSON>;

    export type AdminActionBody = {
        /**
         * Action to perform
         */
        action: 'approve';
        /**
         * Datetime when access expires. Timestamp in milliseconds
         */
        expires: number;
    } | {
        /**
         * Action to perform
         */
        action: 'reject';
        /**
         * Reason for rejecting access grant request
         */
        reason?: string;
    } | {
        /**
         * Action to perform
         */
        action: 'revoke';
    }

    export type AdminActionResponse<IsJSON extends boolean = true> = Customer.AccessGrant<IsJSON>;

    export type GetAuthenticationUrlResponse = {
        url: string;
    };

    export enum ListSortField {
        created = 'created',
        firstAccess = 'firstAccess',
        expires = 'expires',
    }

    export const ACCESS_MAX_TTL_MS = 1000 * 60 * 60 * 24 * 14; // 14 days
}

import type { Locale } from '@/interface/user/user';
import { getGlobalState } from '@/utils/getGlobal';
import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

const initialState: UIState = {
    ...getGlobalState(),
    locale: (localStorage.getItem('locale')! || 'en_US') as Locale,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setUI(state, action: PayloadAction<Partial<UIState>>) {
            Object.assign(state, action.payload);
        },
    },
});

export const { setUI } = uiSlice.actions;

export default uiSlice.reducer;

export type UIState = {
    /** menu collapsed status */
    menuCollapsed: boolean;
    device: 'MOBILE' | 'DESKTOP';
    /** selected language */
    locale: Locale;
}

import * as notification from './listeners/notification';
import * as pipeline from './listeners/pipeline';
import Events, { ServerEvents } from './emitter';

const events = new Events() as ServerEvents;

export function attachListeners(){
    notification.attach(events);
    pipeline.attach(events);
    events.connect();
}

export function detachListeners(){
    notification.detach(events);
    pipeline.detach(events);
}

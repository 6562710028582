import type { FC } from 'react';
import type { RouteObject } from 'react-router';

import { lazy } from 'react';
import { Navigate } from 'react-router';
import { Outlet, useRoutes } from 'react-router-dom';

import CustomersPage from '@/pages/customers';
import AccessCustomersPage from '@/pages/customers/access';
import AddCustomerPage from '@/pages/customers/add';
import ConfigCustomerPage from '@/pages/customers/config';
import MoveCustomerPage from '@/pages/customers/move';
import AccessRequestPage from '@/pages/customers/request';
import Dashboard from '@/pages/dashboard';
import UserTokensPage from '@/pages/facebook/tokens';
import WebhookRouterPage from '@/pages/facebook/webhooks-router';
import AddFacebookWHRouterAppPage from '@/pages/facebook/webhooks-router/add';
import InstancesPage from '@/pages/instances';
import LayoutPage from '@/pages/layout';
import LoginPage from '@/pages/login';
import PartnersPage from '@/pages/partners';
import AddPartnerPage from '@/pages/partners/add';
import EditPartnerPage from '@/pages/partners/edit';
import ViewPartnerPage from '@/pages/partners/view';
import UsersPage from '@/pages/users';
import AddUserPage from '@/pages/users/add';
import EditUserPage from '@/pages/users/edit';
import MePage from '@/pages/users/me';

import { Permission } from '../../../common/permissions';
import WrapperRouteComponent from './config';

const NotFound = lazy(() => import(/* webpackChunkName: "404'"*/ '@/pages/404'));

const routeList: RouteObject[] = [
    {
        path: '/login',
        element: <WrapperRouteComponent element={<LoginPage />} titleId="title.login" />,
    },
    {
        path: '/',
        element: <WrapperRouteComponent element={<LayoutPage />} titleId="" auth />,
        children: [
            {
                path: '',
                element: <Navigate to="dashboard" />,
            },
            {
                path: 'dashboard',
                element: <WrapperRouteComponent element={<Dashboard />} titleId="title.dashboard" auth />,
            },
            {
                path: 'instances',
                element: <WrapperRouteComponent element={<InstancesPage />} titleId="title.instances" auth />,
            },
            {
                path: 'customers',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <WrapperRouteComponent element={<CustomersPage />} titleId="title.customers" auth />,
                    },
                    {
                        path: 'add',
                        element: (
                            <WrapperRouteComponent
                                element={<AddCustomerPage />}
                                titleId="title.customers.add"
                                permissions={[Permission.setupCustomer]}
                                auth
                            />
                        ),
                    },
                    {
                        path: 'config/:instanceId/:customerId',
                        element: (
                            <WrapperRouteComponent
                                element={<ConfigCustomerPage />}
                                titleId="title.customers.config"
                                permissions={[Permission.customersConfigsSafeAccess]}
                                auth
                            />
                        ),
                    },
                    {
                        path: 'move/:instanceId/:customerId',
                        element: (
                            <WrapperRouteComponent
                                element={<MoveCustomerPage />}
                                titleId="title.customers.move"
                                permissions={[Permission.customersMigrateInstances]}
                                auth
                            />
                        ),
                    },
                    {
                        path: 'access',
                        element: <Outlet />,
                        children: [
                            {
                                path: '',
                                element: (
                                    <WrapperRouteComponent
                                        element={<AccessCustomersPage />}
                                        titleId="title.customers.access"
                                        auth
                                    />
                                ),
                            },
                            {
                                path: 'request',
                                element: (
                                    <WrapperRouteComponent
                                        element={<AccessRequestPage />}
                                        titleId="customers.access.requestAccess"
                                        auth
                                    />
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'me',
                element: <WrapperRouteComponent element={<MePage />} titleId="title.me" auth />,
            },
            {
                path: 'users',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: (
                            <WrapperRouteComponent
                                element={<UsersPage />}
                                titleId="title.users"
                                permissions={[Permission.manageUsers]}
                                auth
                            />
                        ),
                    },
                    {
                        path: 'add',
                        element: (
                            <WrapperRouteComponent
                                element={<AddUserPage />}
                                titleId="title.users.add"
                                permissions={[Permission.manageUsers]}
                                auth
                            />
                        ),
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <WrapperRouteComponent
                                element={<EditUserPage />}
                                titleId="title.users.edit"
                                permissions={[Permission.manageUsers]}
                                auth
                            />
                        ),
                    },
                ],
            },
            {
                path: 'partners',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: (
                            <WrapperRouteComponent
                                element={<PartnersPage />}
                                titleId="title.partners"
                                permissions={[Permission.partnersList]}
                                auth
                            />
                        ),
                    },
                    {
                        path: ':partnerId',
                        element: (
                            <WrapperRouteComponent
                                element={<ViewPartnerPage />}
                                titleId="title.partners.profile"
                                auth
                            />
                        ),
                    },
                    {
                        path: ':partnerId/edit',
                        element: (
                            <WrapperRouteComponent element={<EditPartnerPage />} titleId="title.partners.edit" auth />
                        ),
                    },
                    {
                        path: 'partner/add',
                        element: (
                            <WrapperRouteComponent element={<AddPartnerPage />} titleId="title.partners.add" auth />
                        ),
                    },
                ],
            },
            {
                path: 'channels',
                element: <Outlet />,
                children: [
                    {
                        path: '',
                        element: <Navigate to="facebook/tokens" />,
                    },
                    {
                        path: 'facebook/tokens',
                        element: (
                            <WrapperRouteComponent element={<UserTokensPage />} titleId="facebook.tokens.title" auth />
                        ),
                    },
                    {
                        path: 'facebook/webhooks',
                        element: (
                            <WrapperRouteComponent
                                element={<WebhookRouterPage />}
                                titleId="facebook.webhooks.title"
                                permissions={[Permission.facebookWebhooksRouterList]}
                                auth
                            />
                        ),
                    },
                    {
                        path: 'facebook/webhooks/add',
                        element: (
                            <WrapperRouteComponent
                                element={<AddFacebookWHRouterAppPage />}
                                titleId="facebook.webhooks.add"
                                permissions={[Permission.facebookWebhooksRouterAdd]}
                                auth
                            />
                        ),
                    }
                ],
            },
            {
                path: '*',
                element: <WrapperRouteComponent element={<NotFound />} titleId="title.notFound" />,
            },
        ],
    },
];

const RenderRouter: FC = () => {
    const element = useRoutes(routeList);

    return element;
};

export default RenderRouter;

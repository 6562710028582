import type { Permission } from '../../../../common/permissions';
import type { FC } from 'react';

import { useDispatch } from 'react-redux';

import { hasPermissions } from '@/stores/user.action';

interface Props {
    permissions: Permission[];
    children: React.ReactNode;
}

const PermissionGate: FC<Props> = ({ permissions, children }) => {
    const dispatch = useDispatch();
    const permitted = dispatch(hasPermissions(permissions));

    if (!permitted) {
        return null;
    }

    return <>{children}</>;
};

export default PermissionGate;

import type { PayloadAction } from '@reduxjs/toolkit';
import { Notifications as T } from '../../../common/notifications';
import { Pipelines } from '../../../common/pipelines';
import { createSlice } from '@reduxjs/toolkit';

const initialState: NotificationState = {
    notifications: [],
    pipelines: [],
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        /**
         * Set all notifications
         */
        setNotifications(state, action: PayloadAction<T.Notification<true>[]>) {
            state.notifications = [
                ...action.payload,
            ];
        },
        /**
         * Add new notification
         */
        addNotification(state, action: PayloadAction<T.Notification<true>>) {
            const notifications = [
                ...state.notifications, 
                action.payload,
            ]
            // Sort notifications by creation date
            .sort((a, b) => {
                // TS doesn't like comparing Date objects this way, hovewer it is valid JS
                return (a as any).createdAt - (b as any).createdAt;
            });
            
            state.notifications = [...notifications];
        },
        /**
         * Set all pipelines
         */
        setPipelines(state, action: PayloadAction<Pipelines.Run<true>[]>) {
            state.pipelines = [
                ...action.payload,
            ];
        },
        /**
         * Add new pipeline or replace existing one
         */
        upsertPipeline(state, action: PayloadAction<Pipelines.Run<true>>) {
            const pipelines = [
                ...state.pipelines.filter(pipeline => pipeline._id !== action.payload._id),
                action.payload,
            ]
            // Sort pipelines by creation date
            .sort((a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            });
            state.pipelines = [...pipelines];
        },
        deletePipeline(state, action: PayloadAction<string>) {
            state.pipelines = [
                ...state.pipelines.filter(pipeline => pipeline._id !== action.payload),
            ];
        }
    },
});

export const { setNotifications, addNotification, setPipelines, upsertPipeline, deletePipeline } = notificationSlice.actions;

export default notificationSlice.reducer;

export type NotificationState = {
    notifications: T.Notification<true>[];
    pipelines: Pipelines.Run<true>[];
}

